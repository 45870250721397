import React from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    Typography,
    Button,
    Alert
  } from "@material-tailwind/react";

export const Alerta = ({iraCompromiso,style})=>{
    function Icon() {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
        );
      }

    return (
        <div className='pt-52' style={style}>
            <Card className="mt-1 w-[500px]">
                <CardBody>
                    <Typography variant="h5" color="blue-gray" className="mb-2">
                        INFORMACIÓN
                    </Typography>
                    <Alert icon={<Icon />} className='bg-[#EE5B07] text-white'>
                        A partir del 01/08/2022 deberá confirmar el compromiso ministerial para utilizar la credencial
                    </Alert>
                </CardBody>
                <CardFooter className="pt-0">
                    <a href="#" className="inline-block">
                        <Button onClick={iraCompromiso} size="sm" variant="text" className="flex items-center gap-2">
                            Ir al compromiso ministerial
                        </Button>
                    </a>
                </CardFooter>
            </Card>
        </div>)
}


