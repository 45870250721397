import React from 'react';
import { Link, NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faSearchDollar, faPowerOff, faCalendarAlt as calendar2, faClone,faUsers} from '@fortawesome/free-solid-svg-icons';
import { faChartBar } from '@fortawesome/free-regular-svg-icons';

export default function Footer({ usuario, logout, cuatrimestreActivo, mostrarCredencial }) {
  return (
    <div className='flex justify-between min-h-[80px] bg-[#111]/90 py-3 px-4'>
      <div className='flex flex-col'>
        <span className="text-[14px] text-white block w-full text-center">Redes Sociales</span>
        <div className="flex py-0 px-4 justify-center items-center mt-[0px]"> 
          <img 
            src="/facebook.svg" 
            className="relative cursor-pointer hover:opacity-85 w-[32px]" 
            alt="facebook" 
            onClick={() => window.open('https://www.facebook.com/uadoficial') }
          />

          <img 
            src="/instagram.svg" 
            className="relative cursor-pointer hover:opacity-85 ml-2 w-[40px]" 
            alt="instagram" 
            onClick={() => window.open('https://www.instagram.com/uad.oficial/') }
          />
        </div>
      </div> 

      <div className="flex py-5">
        <span className="text-[14px] text-white block">@2024 - Iglesias Sociadas</span>
      </div>
    </div>
  );
}
