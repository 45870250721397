import React from 'react'
import {useState, useEffect} from 'react'
import Axios from 'axios'
import Logo from '../componentes/Logo'
import {useAlumno} from '../Context/alumnoContext';
import Foto from '../componentes/Foto'
import Balances from '../componentes/Balances';
import Main from '../componentes/Main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { stringify } from 'uuid';
//import {useHistory} from 'react-router-dom'
import { useNavigate,useParams } from 'react-router-dom';
import {Alerta} from '../componentes/Alerta'
import {GenericCard} from '../componentes/MUI/GenericCard'
import {FormGroup,FormControlLabel , Grid, Box ,Typography,Button,Checkbox,makeStyles,Card,Chip} from "@material-ui/core";
import CreditScoreIcon from '@mui/icons-material/Badge';
import Divider from '@mui/material/Divider';
import ChurchIcon from '@mui/icons-material/Church';
import DocumentScannerSharpIcon from '@mui/icons-material/Article';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PasswordIcon from '@mui/icons-material/Password';
import {CodigoSeguridad} from '../componentes/CodigoSeguridad'

export default function Home(){
    const {usuario,infoObrero,mostrarMenu,setCurrentMenu} = useAlumno();
//    const history = useHistory()
    const navigate = useNavigate();
    const parametros = useParams();
    const [mostrarAlertaCompromiso,setMostrarAlertaCompromiso] = useState(true)

    useEffect(()=>{
        console.log("user", usuario)
        if(usuario.resetpass){
            navigate('/password')
        }
        if(document.documentElement.clientWidth < 482){
            mostrarMenu() // activo el menu vertical al detectar que estamos en un celular
        }

        navigate('/credencial');
        setCurrentMenu('type1')
    },[])

    if (!usuario){
        return null
    }

    return (<Main center>
            <div className="flex f-col w-100pc justify-center items-center text-center">
                {/*<Logo/>*/} 
                {/*infoObrero.nombre && <p className="mt-4 text-larger">{infoObrero.sexo=='M' ? `Bienvenido ${infoObrero.nombre}` : `Bienvenida ${infoObrero.nombre}`}</p>*/}
                {/*usuario.dependiente && <p className="mt-4 text-small color-gray">{obtenerAsociacion(infoObrero,usuario)}</p>*/}
                <FontAwesomeIcon onClick={mostrarMenu} title="Abrir el menú de opciones" className="cursor-pointer color-tomato text-xlarge mt-4 mnhome" icon={faBars}/>
                {/*mostrarAlertaCompromiso && !infoObrero.compromiso_ministerial_ok && <Alerta close={setMostrarAlertaCompromiso} iraCompromiso={()=>{history.push('/compromiso')}}/>*/}
                {mostrarAlertaCompromiso && !infoObrero.compromiso_ministerial_ok && <Alerta close={setMostrarAlertaCompromiso} iraCompromiso={()=>{navigate('/compromiso')}}/>}
                {/*<ListaCards navigate={navigate}/>*/}
            </div>
            </Main>
    )
}

function obtenerAsociacion(infoObrero,usuario){

    if (usuario.id_iglesia_dependiente){
        if (usuario.id_iglesia_dependiente > 0){ // depende de una iglesia si el id es positivo
            if (infoObrero.sexo=='M'){
                 return `Asociado a la iglesia ${usuario.dependiente}`
            }else{
                return `Asociada a la iglesia ${usuario.dependiente}`
            }
        }else{  // depende de un ministerio si el id es negativo
            if (infoObrero.sexo=='M'){
                return `Asociado al ministerio de ${usuario.dependiente}`
            }else{
                return `Asociada al ministerio de ${usuario.dependiente}`
            }
        }
    }else{
        return ''
    }

}

const items = [
    {
        titulo:'Datos personales',
        subtitulo:'Acceda a sus datos personales y actualice la información',
        ruta:'/info',
        componente:<AccountBoxIcon fontSize="large" color="primary"/>
    },
    {
        titulo:'Credencial',
        subtitulo:'Acceda a su credencial digital',
        ruta:'/credencial',
        componente:<CreditScoreIcon fontSize="large" color="primary"/>
    },
    {
        titulo:'Código de seguridad',
        subtitulo:'Cambie su código de seguridad',
        ruta:'/password',
        componente:<PasswordIcon fontSize="large" color="primary"/>
    },
    {
         titulo:'Iglesias y ministerios',
         subtitulo:'Acceda a los datos de las iglesias o ministerios a su cargo y actualice la información',
         ruta:'/listado/iglesias',
         componente:<ChurchIcon fontSize="large" color="primary"/>
    },
    {
         titulo:'Nómina',
         subtitulo:'Realice búsquedas de ministros e iglesias. Gestione sus ministros y ministerios dependientes',
         ruta:'/nomina',
         componente:<PersonSearchIcon fontSize="large" color="primary"/>
    },
    {
         titulo:'Documentos',
         subtitulo:'Acceda a diferentes documentos de la asociación',
         ruta:'/documentos',
         componente:<PictureAsPdfIcon fontSize="large" color="primary"/>
    },
    {
         titulo:'Compromiso ministerial',
         subtitulo:'Lea y confirme el compromiso ministerial',
         ruta:'/compromiso',
         componente:<ThumbUpOffAltIcon fontSize="large" color="primary"/>
    },
    {
         titulo:'Trámites',
         subtitulo:'Realice gestiones administrativas dentro de la asociación',
         ruta:'/tramites',
         componente:<ListAltIcon fontSize="large" color="primary"/>
    }
]

const ListaCards = ({navigate})=>{
    return <Box sx={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
         {
            items.map(item=>{
                return <GenericCard titulo={item.titulo} 
                estilo={{width:'280px',margin:'4px',padding:'4px',cursor:'pointer'}}
                mostrar={true}
                error={false}
                subtStyle={{minHeight:'55px'}}
                centrado="center"
                noHacerScroll
                subtitulo={item.subtitulo}
                onClick={()=>navigate(item.ruta)}
//                onClick={()=>history.push(item.ruta)}
                >
                     <Box sx={{cursor:'pointer',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                         {item.componente}
                         <Divider sx={{marginBottom:'1rem'}}/>
                     </Box>
            </GenericCard>
            })
         }
        </Box>
}

