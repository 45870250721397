import React, {useEffect} from "react";
import {
  Drawer,
} from "@material-tailwind/react";
import {useAlumno} from '../Context/alumnoContext'
import { MenuSidebar } from "./sidebar";

export function MenuDrawer() {
  const { ocultarMenu, verMenu } = useAlumno()

  useEffect(() => {
    console.log('verMenu',verMenu)
  }, [verMenu])

  const closeDrawer = () => ocultarMenu();

  return (
    <Drawer open={verMenu} onClose={closeDrawer} className="p-4">
      <MenuSidebar displaySidebar={true} />
    </Drawer>
  );
}