import React, {useState, useEffect,useRef} from 'react';
import Main from '../componentes/Main';
import {
    Navbar,
    Typography,
    IconButton,
    Spinner,
    Button,
    CardBody,
    Tabs,
    Tab,
    TabsHeader,
    TabPanel,
    TabsBody
} from "@material-tailwind/react";
import Axios from 'axios';
import Loading from '../componentes/Loading';
import {useAlumno} from '../Context/alumnoContext';
import {compromiso} from '../Helpers/jsons'
import {FormGroup,FormControlLabel , Grid, Box,Checkbox,makeStyles,Card,Chip} from "@material-ui/core";
import {hacerScroll} from '../Helpers/utilidades-globales'
import {CssBaseline,AppBar,Toolbar,Drawer,Divider,List,ListItem,ListItemIcon,ListItemText} from "@material-ui/core";
import MailIcon  from '@mui/icons-material/Mail';
import InboxIcon  from '@mui/icons-material/Inbox';
import PrintIcon  from '@mui/icons-material/Print';
import {ListItemButton} from '@mui/material'
import {Alert} from '@mui/material'
//import {useHistory} from 'react-router-dom'
import Swal from 'sweetalert2';
import { imprimir } from '../impresiones/compromiso'
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;


export default function Compromiso ({ocultar,usuario}){

const [url,setUrl] = useState(null);
const [archivos, setArchivos]= useState([])
const [status, setStatus]= useState(false)
const [buscandoDatos,setBuscandoDatos] = useState(false)
const {confirmarCargandoVista,verMenu,parametros,obtenerObrero} = useAlumno()
const [ultimaLectura,setUltimaLectura] = useState(1)
const [statuscmin,setStatuscmin] = useState(null)
const [contadorOperaciones,setContadorOperaciones] = useState(null)
const [compromisoActualConfirmado,setCompromisoActualConfirmado] = useState(false)
const [readSteps, setReadSteps] = useState([])
const [displayLastStep, setDisplayLastStep] = useState(false)
const [activeTab, setActiveTab] = useState(compromiso.secciones[0].titulo)

//const history = useHistory()
const navigate = useNavigate()


const useStyle = makeStyles({
    tituloGrande: {
        marginTop: '2rem',
        marginBottom:'2rem'
    },
    titulo:{
        marginTop:'1.5rem',
        marginBottom:'1.5rem'
    },
    punto:{
        marginTop:'1rem',
        marginBottom:'1rem'
    }
  });
  const classes = useStyle();

useEffect(()=>{
    const buscarArchivo_old = async ()=>{
        try{
            const {data} = await Axios.get('https://uadfiles.nyc3.digitaloceanspaces.com/compromiso.json')
            console.log(data)
        }catch(err){
            console.log(err)
        }
    }

    const buscarArchivo = async ()=>{
        try{
            const {data} = await fetch('https://uadfiles.nyc3.digitaloceanspaces.com/compromiso.json')
            console.log(data)
        }catch(err){
            console.log(err)
        }
    }

    const buscarStatus = async ()=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/statuscmin/${usuario.id_obrero}`)
            setCompromisoActualConfirmado(data.id_ultimo_cm_aceptado && (Number(data.id_ultimo_cm_aceptado)==Number(data.id_ultimo_cm)))
            setStatuscmin(data)
        }catch(err){
            console.log(err)
        }
    }

   // buscarArchivo() // NO FUNCIONA
    buscarStatus()
},[contadorOperaciones])

const confirmarCompromiso = async ()=>{

    try{

        const objetoaEnviar = {id_compromiso_ministerial:compromiso.version}
        
        const respuesta = await Axios.put(`/api/tablasgenerales/obrero/confirmarcm/${usuario.id_obrero}`,objetoaEnviar)
        
        obtenerObrero(usuario.id_obrero) // este método viene del contexto y actualizaría el objeto global infoObrero

        Swal.fire({
            html:"Se confirmó el compromiso ministerial correctamente",
            icon: 'warning',
            confirmButtonColor: '#3085d6',
        })
       // setContadorOperaciones(Math.floor(Math.random() * 100)) // genero un numero al azar para forzar la rendereización
        //history.push('/home')
        setCompromisoActualConfirmado(true);
    }catch(err){
       
        if(err.response?.data?.message){
            alert(err.response.data.message)
        }else{
            alert('Se produjo un error al grabar el movimiento')
        }
        return 'Se produjo un error al grabar el movimiento'
    }

}

    const continueSteps = (titulo, index) => {
        const found = readSteps.find(item => item === titulo);
        let data = readSteps;
        if (!found) { 
            setReadSteps([...readSteps, titulo]);
            data = [...readSteps, titulo];
        }

        if (data.length === (compromiso.secciones.length -1)) setDisplayLastStep(true);
        setActiveTab(compromiso.secciones[index + 1].titulo);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        document.querySelector('[data-value="'+compromiso.secciones[index + 1].titulo+'"]').click();
    }

    if(buscandoDatos || !statuscmin){
        return (
            <Main center alinear>
                <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                    <Spinner className="h-12 w-12" />
                    <span className="cargando font-semibold text-[15px]">Buscando datos...</span>
                </div>
            </Main>
        )
    }

    if(compromiso.version!=statuscmin.id_ultimo_cm)
    {
        return <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={3}>
            <Alert variant="filled" severity="warning">
               {`La versión (${versionFomarmateada(compromiso.version)}) del presente compromiso ministerial no coincide con la última versión publicada por la sede central (${statuscmin.id_ultimo_cm}) `}
            </Alert>
        </Grid>   
         
      </Grid> 
    }

return (
    <>
        <Navbar
            variant="gradient"
            color="blue-gray"
            className="w-[80%] xl:w-full mx-auto max-w-screen-xl from-blue-gray-900 to-blue-gray-800 px-4 py-3"
        >
        <div className="flex flex-wrap items-center justify-between gap-y-4 text-white">
            <Typography
                as="a"
                href="#"
                variant="h6"
                className="mr-4 ml-2 cursor-pointer py-1.5"
            >
                {compromiso.titulo}
                <span className='flex text-[13px] text-gray-400'>
                    Ultima versión publicada: {versionFomarmateada(statuscmin.id_ultimo_cm)}
                </span>
                <span className='flex text-[13px] text-gray-400'>
                    Ultima versión aceptada: {statuscmin.id_ultimo_cm_aceptado ? `${statuscmin.id_ultimo_cm_aceptado}.0` : 'Ninguna' }
                </span>
            </Typography>
            <div className="ml-auto flex flex-col gap-1 md:mr-4">
                <Typography
                    href="#"
                    variant="h6"
                    className="mr-1 relative top-2 left-[-2px]"
                >
                    Imprimir
                </Typography>
                <IconButton variant="text" color="white" className='flex'>
                    <PrintIcon 
                        onClick={()=> imprimir(
                                compromiso,
                                `Compromiso ministerial versión ${compromiso.version}-0`,`Compromiso ministerial versión ${compromiso.version}-0`
                            )
                        }
                    />
                </IconButton>
            </div>
        </div>
    </Navbar>
    <Card className='w-[75%] m-auto mt-4'>
        <CardBody>
            {!compromisoActualConfirmado && <div className='mt-4'>
                    <Alert variant="outlined" severity="error">
                        <Typography
                            variant="h6"
                            className="relative font-medium text-[14px] top-[1px]"
                        >
                            Aún no ha confirmado el compromiso ministerial actual. A partir del 01/08/2022 no podrá utilizar la credencial.
                        </Typography>
                    </Alert>
                </div>}
                {compromisoActualConfirmado && <div className='mt-4'>
                <Alert variant="outlined" severity="info">
                    <Typography
                        variant="h6"
                        className="relative font-medium text-[14px] top-[1px]"
                    >
                        El compromiso ministerial actual ya ha sido confirmado. Puede utilizar la credencial.
                    </Typography>
                </Alert>
            </div>}

            <Typography className='mt-3'>
                {compromiso.observaciones}
            </Typography>
            <Typography>Versión: {versionFomarmateada(compromiso.version)}</Typography>
            <Typography>Válido a partir del {compromiso.fecha}</Typography>
            <Typography className='w-full text-center font-medium mt-3 mb-3'>{compromiso.subtitulo}</Typography>

            <Tabs value={activeTab}>
                <TabsHeader 
                    className="overflow-x-auto"
                    indicatorProps={{
                        className: "bg-[#05AFF4] shadow-none !text-white",
                    }}
                >
                    {compromiso.secciones.map((item, index) => (
                        <Tab 
                            key={index} 
                            value={item.titulo} 
                            className={`text-[14px] ${activeTab === compromiso.secciones[index].titulo ? 'text-white' : ''}`}
                            onClick={()=> setActiveTab(compromiso.secciones[index].titulo)}
                        >
                            {item.titulo.slice(item.titulo.indexOf('.',0)+1).replace(':','')}
                        </Tab>
                    ))}
                </TabsHeader>
                <TabsBody>
                    {compromiso.secciones.map((item, index) => (
                        <TabPanel key={index} value={item.titulo}>
                            {item.puntos.map(punto => (
                                    <div>
                                        <Typography 
                                            className={classes.punto} 
                                            variant='body1'
                                        >
                                                <span style={{fontWeight:'700'}}>{punto.id} - </span>{punto.texto}
                                        </Typography>
                                    </div>
                                )
                            )}
                            {!compromisoActualConfirmado && displayLastStep && index === (compromiso.secciones.length - 1) ? 
                                    <Button onClick={confirmarCompromiso} color="primary" size="small" className='flex justify-end mt-2'>
                                        Aceptar
                                    </Button>
                                :
                                    null
                            }

                            {!compromisoActualConfirmado && index !== (compromiso.secciones.length - 1) ? 
                                    <Button onClick={()=>{ continueSteps(item.titulo, index)}} color="primary" size="small" className='flex justify-end mt-2'>
                                        Continuar
                                    </Button>
                                :
                                    null
                            }
                        </TabPanel>
                    ))}
                </TabsBody>
            </Tabs>
        </CardBody>
    </Card>

    <Grid style={{ display: 'none' }} container>
        <Grid item xs={12} sm={2}>
            <Box sx={{position:'sticky',top:'0',maxWidth:'150px',display: { xs: "none", sm: "none", md:'block' }}}>
                    <p style={{marginBottom:'1rem'}}>Índice:</p>
                    {compromiso.secciones
                        .map((item,index)=><>
                            <p style={{marginTop:'1rem',cursor:'pointer'}} onClick={()=>hacerScroll(`tit-0${index}`)} >
                                {/*item.titulo.replace(':','').split('.')[1]*/}
                                {item.titulo.slice(item.titulo.indexOf('.',0)+1).replace(':','')}
                            </p>
                        </>)}
                        <BotonImprimir compromiso={compromiso}/>
            </Box>
        </Grid>
        <Grid item xs={12} sm={10} >
        <Box sx={{padding: document.documentElement.clientWidth < 482 ? '0.2rem':'2rem'}}>
                {compromiso.secciones
                        .filter((item,index)=>index<ultimaLectura || compromisoActualConfirmado)
                        .map((item,index)=><Card  style={{background:'#F5F5F5',borderRadius:'10px',padding:'0.5rem',marginBottom:'0.5rem'}} variant="outlined">
                            <Typography variant="h5" className={classes.tituloGrande}>{item.tituloGrande}</Typography>
                            <Typography id={`tit-0${index}`} variant='h5' className={classes.titulo}>{item.titulo}</Typography>
                            <Typography variant='h6'>{item.subtitulo}</Typography>
                            {item.puntos.map(punto=><div>
                                <Typography  className={classes.punto} variant='body1'><span style={{fontWeight:'700'}}>{punto.id} - </span>{punto.texto}</Typography>
                            </div>)}
                        </Card>)}

            {!compromisoActualConfirmado && ultimaLectura<compromiso.secciones.length &&<Box sx={{marginTop:'2rem',display:'flex', justifyContent:'center'}}> 
                <Button variant="contained" color='primary' size='large'  onClick={()=>setUltimaLectura(ultimaLectura+1)}>
                    <Box>
                        <Typography variant='body1'>Continuar a la sección:</Typography>
                        <Typography variant='body1'>{compromiso.secciones[ultimaLectura].titulo}</Typography>
                    </Box>                            
                </Button>    
            </Box>}
        </Box>
                                
            {!compromisoActualConfirmado && (ultimaLectura==compromiso.secciones.length) && <Confirmacion secciones={compromiso.secciones} confirmarCompromiso={confirmarCompromiso}/>}
        </Grid>
    </Grid>

  </>
)


    return     <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <AppBar
      position="fixed"
      sx={{ width: "100px", ml: `${drawerWidth}px` }}
    >
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Permanent drawer
        </Typography>
      </Toolbar>
    </AppBar>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
    <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
    >
      <Toolbar />
      <Typography paragraph>
      Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
          eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
          neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
          tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
          sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
          tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
          gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
          et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
          tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.      </Typography>
      <Typography paragraph>
      Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
          eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
          neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
          tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
          sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
          tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
          gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
          et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
          tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.      </Typography>
    </Box>
  </Box>




}

const Confirmacion = ({secciones,confirmarCompromiso})=>{

    const [elementosSeleccionados, setElementosSeleccionados]= useState({})

    useEffect(()=>{
        const arrayObjetosSeleccionados = Object.entries(elementosSeleccionados)

        const arrayVerificador = arrayObjetosSeleccionados.filter(item=>item[1]==true)

    },[elementosSeleccionados])

    const handleCheck = (e)=>{
       
        if(e.target.checked){
            setElementosSeleccionados({...elementosSeleccionados,[e.target.name]:true})
        }else{
            setElementosSeleccionados({...elementosSeleccionados,[e.target.name]:false})
        }
    }

    const nroElementosOK = ()=>{
        const arrayObjetosSeleccionados = Object.entries(elementosSeleccionados)

        const arrayVerificador = arrayObjetosSeleccionados.filter(item=>item[1]==true)

        return arrayVerificador.length == secciones.length

    }

    return <Box sx={{marginTop:'2rem',display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center '}}> 
    <h4>Para finalizar por favor confirme que ha leido todas las secciones del documento marcando la casilla correspondiente</h4>
    <Grid container style={{maxWidth:'500px'}}>
        <Grid item xs={12} >
            <Box>
                {secciones.map((item,index)=><Box><FormGroup>
                    <FormControlLabel control={<Checkbox onChange={handleCheck} name={`sec-${index}`}/>} label={item.titulo}/>
                </FormGroup> 
                </Box>)}
            </Box>
        </Grid>
        <Grid item xs={12}>
             <Button onClick={confirmarCompromiso} style={{marginTop:'1rem',marginBottom:'1rem'}} disabled={!nroElementosOK()} variant="contained" color='primary' size='large'>
                 Acepto el presente compromiso ministerial
            </Button>
        </Grid>
    </Grid>
</Box>
}

const versionFomarmateada = (version)=>{
    return `${version}.0`
}

const BotonImprimir = ({compromiso})=>{
    return  <Box  sx={{margin:'1rem'}}><Button onClick={()=>imprimir(compromiso,`Compromiso ministerial versión ${compromiso.version}-0`,`Compromiso ministerial versión ${compromiso.version}-0`)} variant="outlined" startIcon={<PrintIcon />}>
    Imprimir
</Button></Box>
}