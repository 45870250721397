import React, {useContext,useEffect,useState} from 'react'
import {FormHelperText, Box ,Switch,FormGroup,FormControlLabel,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'
import {AlertDialog} from '../../../componentes/MUI/AlertDialog'
import {useAlumno} from '../../../Context/alumnoContext'
import {
    Select,
    Option, 
    Chip,
    Button, 
} from "@material-tailwind/react";

export const UbicacionInicial = ({objetoModificacion,provincias,partidos,paso_ubicacion_inicial_ok})=>{
    
    //objetoModificacion es {obj,set}

    const [cargando,setCargando] = useState(true);
    const [id_partido,setIdPartido]=useState(objetoModificacion?.obj?.partido?.id || null)
    const [id_provincia,set_id_provincia] = useState(objetoModificacion?.obj?.provincia?.id || null);
    const [partidos_aux,setPartidos_aux]=useState([])
    const {snackbar} = useAlumno();

    React.useEffect(()=>{
        if(provincias.length>0 && partidos.length>0){
            setCargando(false)
        }
    },[provincias,partidos])

    React.useEffect(()=>{

        if(Number(id_provincia)>0 && partidos.length>0){
            objetoModificacion.set({...objetoModificacion.obj,
                provincia:null,
                partido:null,
                id_region:null,
                distrito:null
            })

            setPartidos_aux(partidos.filter(i=>i.id_provincia==id_provincia).map(j=>{
                return {id:j.id_partido,nombre:j.partido}
            }))
        }

    },[id_provincia])

    React.useEffect(()=>{
        if(id_partido && partidos.length>0){
            const seleccion = partidos.filter(item=>item.id_partido==id_partido)

            if(seleccion.length>0){
                objetoModificacion.set({...objetoModificacion.obj,
                                        provincia:{id:seleccion[0].id_provincia,nombre:seleccion[0].provincia},
                                        partido:{id:seleccion[0].id_partido,nombre:seleccion[0].partido},
                                        id_region:seleccion[0].id_region,
                                        distrito:{id:seleccion[0].id_distrito,nombre:`Distrito ${seleccion[0].id_distrito}`},
                                        })
            }else{
                snackbar.showMessage('No se pudo determinar la región y distrito para la provincia y partido seleccionados','error')
            }
        }
    },[id_partido])

if(cargando){
    return <p className='text-[#111] p-2 text-center'>Cargando datos...</p>
}   

if(objetoModificacion?.partido?.id>0){
    return <h1>{objetoModificacion?.partido?.nombre}</h1>
}

return (
        <Box sx={{marginTop:'20px'}}>
            <div>
                <h3>Ubicación</h3>
                {paso_ubicacion_inicial_ok == true && <FormHelperText className="Mui-error">{ objetoModificacion.obj?.provincia?.id==undefined || objetoModificacion.obj?.partido?.id==undefined ? 'Seleccione una provincia y un partido': ""}</FormHelperText>}
                <Campos partidos_aux={partidos_aux}
                    provincias={provincias}
                    id_partido={id_partido}
                    setIdPartido={setIdPartido}
                    id_provincia={id_provincia}
                    set_id_provincia={set_id_provincia}
                />
            </div>


            {objetoModificacion.obj?.id_region && objetoModificacion.obj?.distrito.id && (
                <Chip
                    variant="ghost"
                    color="green"
                    size="sm"
                    className='px-2 py-3 m-3 text-center text-[14px]'
                    value={`Corresponde a región: ${objetoModificacion.obj?.id_region} distrito: ${objetoModificacion.obj?.distrito?.id}`}
                />
            )}
        </Box>
    )

}

const Campos = ({partidos_aux,provincias,id_partido,setIdPartido,id_provincia,set_id_provincia})=>{

    return <div className='select-personal-data'>
                <div className='form-card'>
                    <div>
                        Provincia<span className='c-obl'></span>
                    </div>

                    {provincias.length > 0 && (
                        <Select 
                            value={id_provincia}
                            onChange={(e) => set_id_provincia(e)}
                        >
                            {
                                [{ id: '', nombre: 'Seleccione una provincia'}, ...provincias].map(item => (
                                    <Option key={`provincias-${item.id}`} value={item.id}>{item.nombre}</Option>
                                    )
                                )
                            }
                        </Select>
                    )}

                </div>
                {id_provincia && <div className='form-card'><div>Partido<span className='c-obl'></span></div>
                {partidos_aux.length > 0 && (
                    <Select 
                        value={id_partido}
                        onChange={(e) => setIdPartido(e)}
                    >
                        {
                            [{ id: '', nombre: 'Seleccione'}, ...partidos_aux].map(item => (
                                <Option key={`partidos-${item.id}`} value={item.id}>{item.nombre}</Option>
                                )
                            )
                        }
                    </Select>
                )}
                </div>}
                   
    </div>
}




