import React, {useContext,useEffect,useState,memo} from 'react'
import {Backdrop, Grid, Box,Paper,makeStyles ,FormControlLabel,Switch,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { hacerfocoEnPrimerInput, hacerScroll } from '../Helpers/utilidades-globales'
import {Formik} from 'formik';
import {LoaderCircular} from '../componentes/LoaderCircular';
import {MuiDate} from '../componentes/formulariosFormik/MuiDate';
import {MuiText} from '../componentes/formulariosFormik/MuiText';
import {MuiSelect} from '../componentes/formulariosFormik/MuiSelect';
import {CustomInput} from '../componentes/formulariosFormik/CustomInput'
import {MuiAutocomplete} from '../componentes/formulariosFormik/MuiAutocomplete';
import {MuiAutocompleteOriginal} from '../componentes/formulariosFormik/MuiAutocompleteOriginal';
import {useAlumno} from '../Context/alumnoContext'
import {AlertDialog} from '../componentes/MUI/AlertDialog'
import {useTramites} from './useTramites'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose,faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import {gql,useQuery,useMutation} from '@apollo/client'
import { Field, Form, ErrorMessage } from 'formik';
import Swal from 'sweetalert2';
import { MuiSwitch } from '../componentes/formulariosFormik/MuiSwitch';
import {
    Button, 
    Typography
  } from "@material-tailwind/react";

//const snackbar = {showMessage:(texto)=>alert(texto)}
/*
const regex_solo_numeros = /^[0-9\b]+$/;
const regex_telefono = /^[0-9-+()\b]+$/;
*/
export const FormularioTramite = ({finalizar,
                                    useTramite,
                                    cancelar,
                                    CustomSubmit,
                                    callbackPrevio, // es un callback que se pasa del contexto de cada trámite y se usa para ejecutar diferentes acciones
                                                    // opcionales propias de cada trámite que además puede agregar o modificar campos que se envian a la mutation
                                    objetoDinamico, // se debe pasar por props o incluir en el context, el hook useTramite es diferente para cada componente que lo importa, no se comparte
                                                    // objetoDinamico tiene información de contexto particular de cada trámite que se pasa
                                                    // a los inputs que se van creando según los campos del formulario
                                                    // De esta forma cada campo del form si tiene una función callback en su configuración
                                                    // (por ejemplo carga de datos en selects) además de las props del formik que es el contexto
                                                    // del formulario también tiene contexto que viene de InicioTramite que es diferente para cada trámite
                                                    //Esta prop también sirve para pasar valores a campos del formulario que necesitamos asignar dinamicamente (por ejemplo initial_value) ya que el valor del campo puede depender del contexto del trámite. Para este propósito también se usa la prop callbackprevio a diferencia que callback previo agrega campos y valores al guardar y objeto dinámico lo hace antes de renderizar el formulario
                                   campos_formulario, 
                                   textoSumbit,
                                   preguntararSiCancela,
                                   callbackError,
                                   setObjetoDinamico, // Originalmente no estaba, se incluye a partir del trámite autonomía para poder permitir a los campos tipo switch modificar el objetodinamico con campos booleanos tipo si_no
                                   callbackValidatePlus // es un conjunto de validaciones opcionales propias de cada trámite que se ejecutan para hacer validaciones adicionales antes de guardar
                                })=>{

    //const {campos_formulario,objetoDinamico} = useContext(tramiteContext)
    const [matrizDatos,setMatrizDatos] = React.useState([])
    //const {campos_formulario} = dataTramite;        
    const {snackbar} = useAlumno()
    const [objetoConfirmacion,setObjetoConfirmacion] = React.useState([]) 
    const referenciaFormik = React.useRef();
    const {mutationPadre,variables,actualizarObjetoDinamico} = useTramite()

    const {initial_values,validate,grupos} = useTramites(campos_formulario);        

     const [mutation] = useMutation(mutationPadre)
    const [loading,setLoading]=React.useState(false)

const guardar = async (values)=>{
let resultadoPrevio = true;
let objetoMutation = {...values}; // por default el objeto que se envía a la mutación son los values del formik
                                  // hay casos en que antes de ejecutar el submit del formulario hay que ejecutar un callback
                                  // y además de ejecutarlo el callback puede traer nuevos campos para enviar
                                  // es el caso por ejemplo de carga de archivos a digitalocean, luego de cargarlos nos
                                  // devuelve la url de cada archivo.
  setLoading(true)
  
    try{
      if(callbackPrevio){
            resultadoPrevio = await callbackPrevio()
      }

       if (!resultadoPrevio[0]){
            //alert(resultadoPrevio[1])
            snackbar.showMessage(resultadoPrevio[1],'error')
            setLoading(false)
            setObjetoConfirmacion([])
            return 
       }else{
            if (resultadoPrevio[1]){
               // alert(JSON.stringify(resultadoPrevio[1]))
               objetoMutation = {...objetoMutation,...resultadoPrevio[1]}
            }
       }
                        // Comienzo COMENTAR CUANDO TERMINE EL DESARROLLO DE NUEVO MINISTRO

                          //      alert(JSON.stringify(objetoMutation))

                          //    return 
                       // fin COMENTAR CUANDO TERMINE EL DESARROLLO DE NUEVO MINISTRO

//       const {data} = await mutation({variables:variables(values)})
       setLoading(true)
       const {data} = await mutation({variables:variables(objetoMutation)})
       setLoading(false)
       Swal.fire({
            text:Object.values(data)[0], // lo hacemos asi porque el resultado viene data.nombreMutation y no sabemos cual es el nombre de la mutacion porque viene de diferentes trámites. Por eso lo transformamos a array y tomamos el 0 ya que sabemos que viene el retorno.
            icon: 'warning',
            confirmButtonColor: '#3085d6',
        })   

      // alert(JSON.stringify(data))
       finalizar()
   }catch(err){
       console.log(JSON.stringify(err))
       setLoading(false)
       /*
       {"name":"ApolloError","graphQLErrors":[],"protocolErrors":[],"clientErrors":[],"networkError":{"name":"ServerError","response":{},"statusCode":500,"result":{"errors":[{"message":"Variable \"$nombreEncargado\" of required type \"String!\" was not provided.","locations":[{"line":1,"column":250}]},{"message":"Variable \"$distrito_id\" of required type \"Int!\" was not provided.","locations":[{"line":1,"column":319}]}]}},"message":"Response not successful: Received status code 500"}
       */
       console.log(err.message) // Response not successful: Received status code 500
       
       if(callbackError){
            callbackError()
       }

       if(err?.networkError?.result?.errors){
        snackbar.showMessage(err?.networkError?.result?.errors?.reduce((acum,item)=>{return `${acum} ${item.message}`},''),'error')
        //alert(err?.networkError?.result?.errors?.reduce((acum,item)=>{return `${acum} ${item.message}`},''))
       }else{
        snackbar.showMessage(err?.message || JSON.stringify(err) || 'Error al ejecutar la operación.','error')
       // alert(err?.message || JSON.stringify(err) || 'Error al ejecutar la operación.')
       }
   }
} 
/*
const guardar_OK = async (values)=>{

  try{
       const {data} = await nuevo_anexo({variables:{
          nombre:values.nombre,
          region:Number(values.id_region),
          direccion:values.direccion,
          localidad:values.localidad,
          cod_postal:values.cod_postal,
          provincia:values.provincia,
          id_provincia:values.id_provincia,
          telefono:values.telefono,
          celular:values.celular,
          iglesia_madre:values.iglesia_madre,
          iglesia_madre_id:values.iglesia_madre_id,
          nombreEncargado:values.encargado,
          encargadoEsUad:true,
          id_pastor_uad:values.id_ministro,
          distrito:values.distrito,
          distrito_id:values.id_distrito,
          usuario:usuario.nombre,
          usuario_id:usuario.id_obrero
       }})
       alert(JSON.stringify(data))
       finalizar()
   }catch(err){
       console.log(JSON.stringify(err))
       
       //{"name":"ApolloError","graphQLErrors":[],"protocolErrors":[],"clientErrors":[],"networkError":{"name":"ServerError","response":{},"statusCode":500,"result":{"errors":[{"message":"Variable \"$nombreEncargado\" of required type \"String!\" was not provided.","locations":[{"line":1,"column":250}]},{"message":"Variable \"$distrito_id\" of required type \"Int!\" was not provided.","locations":[{"line":1,"column":319}]}]}},"message":"Response not successful: Received status code 500"}
       
       console.log(err.message) // Response not successful: Received status code 500

       if(err?.networkError?.result?.errors){
        alert(err?.networkError?.result?.errors?.reduce((acum,item)=>{return `${acum} ${item.message}`},''))
       }else{
        alert(err?.message || JSON.stringify(err) || 'Error al ejecutar la operación.')
       }
   }
} 
*/

    useEffect(()=>{
      hacerfocoEnPrimerInput('dp-nombre')
    },[])

    const useStyle = makeStyles({
        selectpais: {
            background:'gray',
            color:'black'
        },
        selectprovincia: {
            background: 'gray',
            color:'black'
        },
      });

    
    const classes = useStyle();

    const handleDelete = (item,setFieldValue,errors,values,setFieldError,setFieldTouched)=>{
     
        setFieldValue(item.name,'')
    
          const dependientes = campos_formulario.filter(campo=>campo.requiredIf==item.name || campo.allowedIf==item.name || campo.dependeDe ==item.name)
          
          for (const field of dependientes){
            setFieldValue(field.name,'')
            setFieldError(field.name,'')
            setFieldTouched(field.name, false, false)      
         }
    }
 
    const iniciarEliminarCampos = ()=>{
            alert('Iniciar eliminar campos')
    }

    const iniciarFinalizar = (props) =>{
        const {values,dirty} = props;

        if(preguntararSiCancela || props.dirty){

            /*setObjetoConfirmacion([true,
                                    finalizar,
                                    ()=>setObjetoConfirmacion([]),
                                    'Por favor confirme la cancelación del formulario'])
                                    */

            Swal.fire({
                title: preguntararSiCancela?.texto1 || '',
                text:preguntararSiCancela?.texto2 || `Por favor confirme la cancelación del formulario`,
                showCancelButton:true,
                confirmButtonText:'OK',
                cancelButtonText:'Cancelar'
            }).then(
                resultado=>{
                    if (resultado.value){
                        if(cancelar){
                            cancelar()
                        }else{
                            finalizar()
                        }
                    }else{
                        console.log("Se canceló la operación de la solicitud")
                    }
                }
            )

        }else{
            if(cancelar){
                cancelar()
            }else{
                finalizar()
            }
        }
    }

    const iniciarGuardar = (values) =>{

          if(callbackValidatePlus){
                callbackValidatePlus(values)
                .then(resultado=>{
                        if (resultado[0]==false){
                            snackbar.showMessage(resultado[1],'error')
                            //alert(resultado[1],'error')
                            return 
                        }else{
                            confirmarGuardar(values)
                        }
                })
          }else{
            confirmarGuardar(values)
          }
  }

  const confirmarGuardar = (values)=>{
    /*
    setObjetoConfirmacion([true,
        ()=>guardar(values),
        ()=>setObjetoConfirmacion([]),
        'Por favor confirme la operación'])
        */
       if(!CustomSubmit){
            Swal.fire({
                text:`Por favor revise atentamente los datos suministrados y confirme la operación`,
                showCancelButton:true,
                confirmButtonText:'Continuar',
                cancelButtonText:'Cancelar'
            }).then(
                resultado=>{
                    if (resultado.value){
                        guardar(values);
                    }else{
                        console.log("Se canceló la operación de la solicitud")
                    }
                }
            )
       }else{
            guardar(values);
       }
  }
/*    if(loading){
        return <h1>Cargando...</h1>
    }

    if(error?.networkError?.result?.errors){
      return <h1>{error?.networkError?.result?.errors?.map(error=><p>{error.message}</p>)}</h1>
    }
*/

    return <div>
            <Backdrop
                style={{ background: 'black',color:'white', zIndex: 1001,opacity:'0.85' }}
                open={loading}
                >
                    <LoaderCircular/>
            </Backdrop>
          <Formik 
                /* ATENCIÓN: Recordar que todo cambio en el objeto objetoDinamico hará que se renderice
                nuevamente el formik. El objetoDinamico es modificado en el componente InicioTramite de cada
                trámite y puede haber 0 o N sub componentes que vayan agregando diferentes atributos al
                objetoDinamico. 
                La idea es que finalmente el objeto values del formik esté integrado por los campos del formulario
                (campos_formulario) + objetoDinamico.
                Si no quiero que el formik se renderice nuevamente y vuelva a presentar sus valores iniciales
                por un cambio en el objetoDinamico, lo que hay que hacer es separar la lógica del trámite en
                distintas pantallas con un flag que según se cumplan condiciones se vayan pasando a true
                para pasar a la siguiente vista hasta que la última sea <FormularioTramite>
                */
                initialValues={{...initial_values,...objetoDinamico}} // el objeto objetoDinamico también puede usarse para asignar valores a propiedades de los campos del formulario que sean modificados en el contexto del trámite (InicioTramite) por ejemplo initial_values u otro campo que modifique dinámicamente lo que está configurado en el json de campos  
                validate={(values)=>validate(values,snackbar)}
                enableReinitialize
                innerRef={referenciaFormik}
                onSubmit={(values)=>iniciarGuardar(values,snackbar)}
              >
               {props => {
                    const {handleSubmit,values,isSubmitting,errors,touched,dirty,validateForm } = props;

                    return <><form onSubmit={handleSubmit} className='grid grid-cols-1 lg:grid-flow-row-dense lg:grid-cols-3 lg:gap-3'> 
                    {/*<p style={{wordWrap:'break-word'}}>{JSON.stringify(values)}</p>*/}
                            {/*<div style={{position:'absolute',top:'0',left:'0',width:'100%'}}>
                              <div className='cursor-pointer text-center' title='Cerrar' onClick={()=>iniciarFinalizar(props)}>
                                  <FontAwesomeIcon className="color-red text-xlarge"  icon={faTimesCircle}/>
                              </div>
                            </div>*/}
                            {
                                [null,...grupos].filter(grupo=>hayCamposEnEsteGrupo(campos_formulario,values,grupo)).map(grupo=>{
                                        return <>
                                            <h3 className='hidden mt-4 mb-[-5px]'>{grupo}</h3>
                                            {campos_formulario.filter(i=>i.grupo==grupo && i.visible==true).map(item=>
                                                      {
                                                        if(item.type=='text'){
                                                            return (
                                                                <MuiText 
                                                                    item={item} 
                                                                    props={props} 
                                                                    handleDelete={handleDelete}  
                                                                    esAtributoOpcional={esAtributoOpcional} 
                                                                    objetoDinamico={objetoDinamico}
                                                                    //usuario={usuario}
                                                                />
                                                            )
                                                        }else if (item.type=='select'){
                                                            return (
                                                                <MuiSelect item={item} 
                                                                    props={props} 
                                                                    handleDelete={handleDelete}
                                                                    objetoDinamico={objetoDinamico}
                                                                    matrizDatos={matrizDatos} 
                                                                    eliminar={iniciarEliminarCampos} 
                                                                    setMatrizDatos={setMatrizDatos} 
                                                                    esAtributoOpcional={esAtributoOpcional} 
                                                                    //usuario={usuario}
                                                                    />
                                                            )
                                                        }else if (item.type=='date'){
                                                            return <MuiDate item={item} props={props} 
                                                                    handleDelete={handleDelete} 
                                                                    snackbar={snackbar} 
                                                                    objetoDinamico={objetoDinamico}
                                                                    matrizDatos={matrizDatos} 
                                                                    eliminar={iniciarEliminarCampos} 
                                                                    setMatrizDatos={setMatrizDatos} 
                                                                    dependientes={campos_formulario.filter(dependiente=>dependiente.type=='time' && dependiente.allowedIf==item.name)} 
                                                                    //usuario={usuario}
                                                                    />
                                                        }else if(item.type=='time'){
                                                            return null // El componente time se incluye en <MuiDate></MuiDate>
                                                        } else if (item.type=='array'){
                                                            return <CustomInput item={item} 
                                                                                props={props} 
                                                                                objetoDinamico={objetoDinamico}
                                                                                handleDelete={handleDelete} 
                                                                                //usuario={usuario}
                                                                                />
                                                        }else if (item.type=='autocomplete'){
                                                            return <MuiAutocomplete item={item} 
                                                                                    props={props} 
                                                                                    objetoDinamico={objetoDinamico}
                                                                                    handleDelete={handleDelete} 
                                                                                    matrizDatos={matrizDatos} 
                                                                                    eliminar={iniciarEliminarCampos} 
                                                                                    setMatrizDatos={setMatrizDatos} 
                                                                                    esAtributoOpcional={esAtributoOpcional} 
                                                                                    //usuario={usuario}
                                                                                    />
                                                        }else if (item.type=='custom'){
                                                            return <CustomInput item={item} 
                                                                                props={props} 
                                                                                objetoDinamico={objetoDinamico}
                                                                                //usuario={usuario}
                                                                                />
                                                       }else if(item.type=='copy'){
                                                            return <Field type="text" 
                                                            name={item.name} 
                                                            objetoDinamico={objetoDinamico}
                                                            />
                                                       }else if(item.type=='switch'){
                                                            return <MuiSwitch  item={item} props = {props} objetoDinamico={objetoDinamico} setObjetoDinamico={setObjetoDinamico}/>
                                                       }
                                                        else{
                                                            return <p>El tipo de componente válido en objeto de configuración para el campo {item.name}</p>
                                                        }
                                                    }
                                            )}
                                            {/*<MuiAutocomplete id_ministro={685} handleChange={(e)=>alert(e.target.value)}/>*/}
                                        </>
                                })
                            }
 
                        </form>

                        <Box className='flex flex-row justify-center mt-[40px]'>
                            <Button variant="outlined" className='gap-1 text-center w-full border-solid border-[2px] border-gray-700' onClick={()=>iniciarFinalizar(props)}>
                                cancelar
                            </Button>
                            {!CustomSubmit && <Button variant='outlined' className='bg-blue-400 text-white w-full text-center' type="submit">
                                {textoSumbit || "Enviar"}
                            </Button>}
                            {CustomSubmit!=undefined && <CustomSubmit submit={handleSubmit} values={values} validate={validate} props={props}/>}
                        </Box>
                        </>
                    }

               }
            </Formik>
            <AlertDialog
                titulo="Confirme"
                open={objetoConfirmacion[0]}
                procesarCancelar={objetoConfirmacion[2]}
                procesarResultado={objetoConfirmacion[1]}
            >
              {objetoConfirmacion[3]}
            </AlertDialog>
    </div>
}

const esAtributoOpcional = (item)=>{

    return item.required==false 
           && !item.requiredIf 
           && !item.allowedIf 
           && !item.mustBePresent
  }
  
const hayCamposEnEsteGrupo = (campos_form_producto2,values,p_grupo)=>{
  // De la plantilla de campos filtrar por aquellos que están en el formulario y cuyo grupo sea igual a p_grupo  
  const key_values_as_array = Object.keys(values)
  
  const array_de_coincidencias = campos_form_producto2.filter(campo=>key_values_as_array.some(key=>key==campo.name) && campo.grupo==p_grupo)
    
    return  array_de_coincidencias.length > 0
  }

