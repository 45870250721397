import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import Main from './Main';
import {
    Chip,
    Spinner,
    Typography
} from "@material-tailwind/react";

export default function BalancesV2({id_iglesia}){

const [datos,setDatos] = useState([])
const [buscandoDatos,setBuscandoDatos] = useState(false);
const TABLE_HEAD = ["Periodo", "Estado"];

useEffect(() => {
    const obtenerDatos = async () => {
        setBuscandoDatos(true)
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/balances/${id_iglesia}`)

            setDatos(data)
            setBuscandoDatos(false)

        }catch(err){
            console.log(err)
            setBuscandoDatos(false)
        }
    }
    obtenerDatos()               
}, [id_iglesia])

    if (buscandoDatos){
        return (          
            <Main center alinear>
                <div className='flex flex-col justify-center items-center'>
                    <Spinner className="h-12 w-12" />
                    <span className="cargando font-semibold text-[15px]">Buscando balances...</span>
                </div>
            </Main>
        )
    };    

    return (
        <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
            <tr>
                {TABLE_HEAD.map((head) => (
                <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                    >
                    {head}
                    </Typography>
                </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {datos.map(
                ({ balance, periodo, estado }, index) => {
                const isLast = index === datos.length - 1;
                const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";

                return (
                    <tr key={index}>
                        <td className={classes}>
                            <div className="flex flex-col">
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                >
                                    {periodo}
                                </Typography>
                            </div>
                        </td>
                        <td className={classes}>
                            <div className="w-max">
                                <Chip
                                    variant="ghost"
                                    size="sm"
                                    value={estado === 1 ? "Presentado" : "No Presentado"}
                                    color={estado === 1 ? "green" : "red"}
                                />
                            </div>
                        </td>
                    </tr>
                );
                },
            )}
            </tbody>
        </table>
    )
}