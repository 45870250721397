import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Logo from '../Logo'
import {useAlumno} from '../../Context/alumnoContext'
import {Notificaciones} from '../../componentes/Notificaciones';
import {
  ArrowLeftStartOnRectangleIcon,
  Bars3Icon
} from "@heroicons/react/24/solid";

export const MenuAppBar = ({usuario, logout})=> {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    infoObrero,
    esCelular,
    mostrarMenu
  } = useAlumno()

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className='w-full ml-0 mr-0 lg:ml-6 lg:mr-6'>
      <AppBar 
        position="static" 
        className='py-2 !shadow-none !bg-white border-b-[2px] border-b-[#ccc] lg:border-[2px] lg:border-white lg:rounded-md lg:!shadow-md lg:mt-4'
      >
        <Toolbar sx={{justifyContent:'space-between'}}>
          <div className="hidden lg:flex lg:flex-col mb-[-15px] p-4">
            <Typography variant="h5" className="!text-[#2196f3] w-full !font-bold">
              Bienvenido
            </Typography>
            <Typography variant="h6" className='text-[#111] w-full'>
              {infoObrero.sexo=='M' ? ` ${infoObrero.nombre}` : ` ${infoObrero.nombre}`}
            </Typography>
          </div>
          <Box className="flex flex-row pl-1 lg:ml-[-140px]">
            <Bars3Icon className="flex lg:hidden relative top-[5px] mr-3 h-6 w-6 text-[#111]" onClick={() => { mostrarMenu(); }}/>
            <Logo width={75}/>
          </Box>
          {/*<Notificaciones usuario={usuario}/>*/}
            <div>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                className='!text-[#111] flex flex-row !rounded-md px-2'
                onClick={logout}
                color="inherit"
              >
                Salir
                <ArrowLeftStartOnRectangleIcon className="relative top-[1px] ml-1 h-5 w-5 text-[#111]" />
              </IconButton>
            
            </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

