import React, {useContext,useEffect,useState} from 'react'
import {Box ,Switch,FormGroup,FormControlLabel,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'
import {
    Select,
    Option, 
    Button, 
  } from "@material-tailwind/react";

export const Iglesias = ({usuario,
                            iglesiaSeleccionada,
                            setIglesiaSeleccionada,
                            setIglesias
                        })=>{
    const [datos,setDatos]=useState([])
    const [seleccionIn,setSeleccionIn] = useState(null)

    React.useEffect(()=>{
        buscarIglesias()
    },[])

    const buscarIglesias = async ()=>{
        try{
            const {data} = await Axios.get(`api/tablasgenerales/iglesias/${usuario.id_obrero}`)
            setDatos(data.map(item=>{
                return {id:item.id_iglesia,nombre:item.nombre_igl}
            }))
            setIglesias(data)
        }catch(err){
            alert(err)
        }
    }

return <Box className="select-personal-data m-[20px]">
  {datos.length > 0 ? (
    <Select 
        value={seleccionIn}
        onChange={(e) => setSeleccionIn(e)}
      >
        {
          [{ id: '', nombre: 'Seleccione una iglesia autónoma'}, ...datos].map(item => (
              <Option key={`iglesia${item.id}`} value={item.id}>{item.nombre}</Option>
            )
          )
        }
    </Select>
  ) : <p className='text-[#111] p-2 text-center'>Cargando datos...</p>}
  {seleccionIn && <Button className='mt-[15px] relative top-[10px]' color='primary' onClick={()=>setIglesiaSeleccionada(seleccionIn)}>Continuar</Button>}
</Box>

}


