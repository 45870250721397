import React, {useContext,useEffect,useState} from 'react'
import {TextField, FormHelperText, Box,FormGroup,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from "@mui/material/Autocomplete";
import {AlertDialog} from '../../../componentes/MUI/AlertDialog'
import {hacerFoco} from  '../../../Helpers/utilidades-globales';
import {useAlumno} from '../../../Context/alumnoContext';
import {default as TailwindSelect} from "react-tailwindcss-select";
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import BadgeIcon from '@mui/icons-material/Badge';
import {
    Button, 
} from "@material-tailwind/react";
import { CheckBadgeIcon, MinusCircleIcon } from '@heroicons/react/24/outline';

/*
Ejemplo de autocomplete.
El array de opciones espera tener un id y un label

 <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={[{id:1,label:'pepe'},{id:2,label:'chocho'}]}
      onChange={(event, value) =>handleChangeTipoSalida(event)}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Movie" />}
    />
*/

export const Encargado = ({setEncargado,ministros,encargado,paso_encargado_inicial_ok})=>{
    const [ministroSeleccionado,setMinistroSeleccionado]=useState(encargado ? {id:encargado?.id_pastor_uad,nombre:encargado?.nombreEncargado} :null)
    const [encargadoExterno,setEncargadoExterno]=useState(encargado ? encargado?.nombreEncargado : null)
    const [esUad,setEsUad]=useState(encargado ? encargado?.encargadoEsUad : null);
    const {snackbar} = useAlumno();

    useEffect(()=>{

        if(esUad==true){
            // es el caso en que modifica el tipo de encargado a UAD
            setEncargadoExterno(null)
            setEncargado({encargadoEsUad:true,nombreEncargado:null,id_pastor_uad:null})
            setTimeout(() => {
                hacerFoco('enc-uad')
            }, 100);
        }
        
        if(esUad==false){
            
            // es el caso en que modifica el tipo de encargado a externo
            setMinistroSeleccionado(null)
            setEncargado({encargadoEsUad:false,nombreEncargado:null,id_pastor_uad:null})
            setTimeout(() => {
                hacerFoco('enc-externo')
            }, 100);
        }
    },[esUad]) // aquí es importante el orden de los efectos. Primero hacer las acciones correspondientes
                // a la selección de que tipo de encargado es, luego que ejecute los efectos
                // del cambio de encargado según si es uad (con id de pastor) o externo
                // si el useEffect esUad se ubica después del effect de ministroSeleccionado y
                // encargadoExterno primero se ejecutaban estos 2 últimos y luego pasaba al effect
                // esUad pisando los datos.

    useEffect(()=>{
        if(esUad==true){
            if(Number(ministroSeleccionado?.id)>0){
                setEncargado({encargadoEsUad:true,nombreEncargado:ministroSeleccionado?.nombre,id_pastor_uad:ministroSeleccionado?.id})
            }else{
                //es el caso en que eliminó el ministro UAD seleccionado previamente
                setMinistroSeleccionado(null)
                setEncargado({encargadoEsUad:true,nombreEncargado:null,id_pastor_uad:null})
            }
        }
       
    },[ministroSeleccionado])

    useEffect(()=>{

        if(esUad==false){
            if(encargadoExterno!=''){
                setEncargado({encargadoEsUad:false,nombreEncargado:encargadoExterno,id_pastor_uad:null})
            }else{
                //es el caso en que borra el ministro EXTERNO ingresado previamente
                setEncargadoExterno(null)
                setEncargado({encargadoEsUad:false,nombreEncargado:null,id_pastor_uad:null})
            }
        }
    },[encargadoExterno])

    const filterOptions = createFilterOptions({
        stringify: (option) => quitaracentos(option.label)
    });

    function quitaracentos(cadena)
{
	var chars={
		"á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
		"à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n",
		"Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
		"À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
	var expr=/[áàéèíìóòúùñ]/ig;
	var res=cadena.replace(expr,function(e){return chars[e]});
	return res;
}

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    const changeOnblur = (e)=>{
        if(e.target.value.trim()==''){
            setEncargadoExterno(null)
        }
    }

    if(ministros.length==0){
        return <p>Buscando ministros...</p>
    }
   
//if(encargado?.nombreEncargado==undefined){
if(paso_encargado_inicial_ok==false){
    return <>
        <h3>Encargado del anexo:</h3>
        <Box className='flex flex-row justify-center items-center mt-4 mb-3'>
            <Box>
                <Button className='flex' color={ esUad==true ? 'primary' : 'error'} variant='contained' onClick={()=>setEsUad(true)}>
                    <CheckBadgeIcon width={20}/> 
                    <span className='relative top-[2px] ml-2'>Tiene credenciales UAD</span>
                </Button>
            </Box>         
            <Box>
                <Button className='flex' color={esUad==false ? 'primary' : 'error'} variant='contained' onClick={()=>setEsUad(false)}>
                    <MinusCircleIcon width={20}/> 
                    <span className='relative top-[2px] ml-2'>No tiene credenciales UAD</span>
                </Button>
            </Box>   
        </Box>
        <Campos esUad={esUad} encargadoExterno={encargadoExterno} setEncargadoExterno={setEncargadoExterno} ministroSeleccionado={ministroSeleccionado} setMinistroSeleccionado={setMinistroSeleccionado} changeOnblur={changeOnblur} datos={ministros}/>
   </>   
}  

return  <Box>
    <div>
        <h3>Encargado del anexo</h3>
        <FormHelperText className="Mui-error">{ (!encargadoExterno || encargadoExterno.trim()=='') && !ministroSeleccionado ? 'Seleccione un encargado UAD o escriba el nombre y apellido si no tiene credenciales UAD': ""}</FormHelperText>
        <Campos esUad={esUad} encargadoExterno={encargadoExterno} setEncargadoExterno={setEncargadoExterno} ministroSeleccionado={ministroSeleccionado} setMinistroSeleccionado={setMinistroSeleccionado} changeOnblur={changeOnblur} datos={ministros}/>
        <Box sx={{display:'flex',justifyContent:'end',marginTop:'10px'}}>
            <Button variant='text' style={{textTransform:'none',color:'#111'}} onClick={()=>setEsUad(!esUad)}>{esUad==true ? 'Si el encargado no tiene credenciales UAD haga clic aquí' : 'Si el encargado tiene credenciales UAD haga clic aquí'}</Button>
        </Box>

    </div> 
      
      {/*<AlertDialog
                titulo="Por favor indique si el encargado tiene credenciales UAD"
                open={!continuar}
                fullWidth={true}
                style={{background:'gray'}}
                procesarCancelar={finalizar}
                procesarResultado={esUad==null ? false : iniciarContinuar}
            ><>
            <p>esUad: {typeof(esUad)} continuar: {typeof(continuar)} {continuar===false ? 'fasle' : continuar===true ? 'true' :  'null'}</p>

            <Box sx={{display:'flex',justifyContent:'space-evenly'}}>
                <Button startIcon={<BadgeIcon style={{color:'green'}}/>} color={ esUad==true ? 'primary' : 'error'} variant='contained' onClick={()=>setEsUad(true)}>Si, tiene credenciales UAD</Button>
                <Button startIcon={<NoAccountsIcon style={{color:'red'}}/>}  color={esUad==false ? 'primary' : 'error'} variant='contained' onClick={()=>setEsUad(false)}>No, no tiene credenciales UAD</Button>
            </Box>
            <Campos esUad={esUad} encargadoExterno={encargadoExterno} setEncargadoExterno={setEncargadoExterno} ministroSeleccionado={ministroSeleccionado} setMinistroSeleccionado={setMinistroSeleccionado} changeOnblur={changeOnblur} datos={datos}/>
            </>
    </AlertDialog>*/}
</Box>
/*
return    <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',textAlign:'left' }}>
  <button onClick={()=>formik.setFieldError('id_ministro','blabla')}>probar</button>
     <Autocomplete
        disablePortal
        fullWidth
        filterOptions={filterOptions} // se utiliza para manipular los campos de búsqueda
        id="ac-ministros"
    //    value = {values[item.name]!='' ? datos.filter(item=>item[0]==values[item.name]).map(item=>item[1])[0] : ''}
        value = {datos.filter(item=>item[0]==Number(formik.values['id_ministro'])).map(item=>item[1])[0]}
        options={datos.map(item=>{return {id:item[0],label:item[1]}})}
        onChange={(event, value) => customHandleChange(event,value)}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={formik.values['id_ministro']==''? 'Seleccione un valor':'Encargado'} />}
        />
        <FormHelperText className="Mui-error">{formik.errors['id_ministro'] ? formik.errors['id_ministro'] : ""}</FormHelperText>

</Box>
*/
  }

const Campos = ({setMinistroSeleccionado,setEncargadoExterno,esUad,ministroSeleccionado,encargadoExterno,changeOnblur,datos})=>{
 return   <Box sx={{marginTop:'10px'}} className='select-mui'>
    {esUad==true && <TailwindSelect
            value={{ value: ministroSeleccionado?.id ?? '', label: ministroSeleccionado?.nombre ?? '' }}
            onChange={(event)=>setMinistroSeleccionado({ id: event.value, nombre: event.label })}
            options={datos.map(item => { return {value:item.id, label:item.nombre}})}
            searchInputPlaceholder="Buscar encargado UAD"
            placeholder="Seleccione un encargado UAD"
            isSearchable={true}
        />}
    {esUad==false && <TextField 
                        //style={{background: ministroSeleccionado ? 'gray' : ''}} 
                        onBlur={changeOnblur} 
                        label={encargadoExterno ? 'Nombre y apellido del encargado externo': 'Escriba el nombre y apellido aquí'} 
                        //disabled={ministroSeleccionado ? true : false} 
                        fullWidth={true} 
                        type='text' 
                        id='enc-externo'
                        value={encargadoExterno || ''} 
                        placeholder='Nombre del encargado externo' 
                        onChange={(e)=>setEncargadoExterno(e.target.value.toUpperCase())}
                />}
    </Box>
}

