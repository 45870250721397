import React from 'react';
import {useState, useEffect} from 'react';
import Main from '../../componentes/Main';
import Axios from 'axios';
import Loading from '../../componentes/Loading';
import Swal from 'sweetalert2';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faWindowClose,faCheckCircle,faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle, faMapMarkerAlt, faUndo } from '@fortawesome/free-solid-svg-icons';
import {hacerfocoEnPrimerInput,seleccionarTextoInput,traerUrl} from '../../Helpers/utilidades-globales';
import { Card, Spinner, Button, Typography, Input } from "@material-tailwind/react";
import useModal from '../../hooks/useModal';
import Modal from '../../componentes/Modal';
import AbmIglesias from '../../abms/Abm-iglesia';
import {useAlumno} from '../../Context/alumnoContext';
import { PencilIcon } from '@heroicons/react/24/outline';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import AbmObreroEditionForm from './edit-form';

export default function AbmObrero({id_obrero, finalizarAltaOcopia,esModal,id_copia, usuario}){
    
    const provinciaDefault = [{id_provincia:-1, nombre:"Seleccionar país"}]

    const {ocultarMenu,confirmarCargandoVista} = useAlumno()
    // estados flags 
    const [cargandoDatosTablasGenerales,setCargandoTablasGenerales] = useState(false);
    const [cargandoDatosObrero,setCargandoDatosObrero] = useState(false);
    const [grabandoDatosObrero,setGrabandoDatosObrero] = useState(false);
    const [tablasCargadas,setTablasCargadas]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const [tituloAbm, setTituloAbm]=useState('');
    const [tituloCerrar,setTituloCerrar]=useState('');
    const [contadorOperaciones,setContadorOperaciones]=useState(0);
    // vectores de selección de formulario

    const [vectorDias, setVectorDias] = useState([]);
    const [vectorMeses, setVectorMeses]=useState([]);
    const [vectorAnios, setVectorAnios] = useState([]);
    
    // vectores de selección de otras operaciones
    const [provincias,setProvincias] = useState([]); 
    const [nacionalidades, setNacionalidades]= useState([]);
    const [estadosCiviles, setEstadosCiviles]= useState([]);
    const [regiones, setRegiones]= useState([]);
    const [rangos, setRangos]= useState([]);
    const [tiposDocumentos, setTiposDocumentos]= useState([]);

    const [iglesiaSeleccionada,setIglesiaSeleccionada]= useState(null);
    // Variables para manejar otras operaciones
    const [contadorModificaciones,setContadorModificaciones]=useState(0);
    const[datosParaImpresiones,setDatosParaImpresiones]=useState(null);
    const [iglesias,setIglesias]=useState([]);
    const [isEdition, setIsEdition]= useState(false);
    const [foto, setFoto]= useState(null);
    const [foto4x4, setFoto4x4]= useState(null);

    const {toggle, isShowing } = useModal();

  
    // estado objeto de inicialización que inicializa los valores del abm 
    // en el alta o es cargado con los valores de la base de datos en una modificación
    // este objeto se pasa al formulario Formik para que el estado del formulario se inicialice
    // con este objeto. Luego el resto del trabajo se hace sobre el estado del formulario  
    const [objetoInicializacion,setObjetoInicializacion]=useState({
        nombre:'',
        direccion:'',
        barrio:'',
        cod_postal:'',
        localidad:'',
        id_provincia:'',        
        email:'',
        telefono:'',
        celular:'',
        conyuge:'',
        anio:"2020",
        mes:"01",
        dia:"01",
        sexo:'M',
        pastor:false,
        maestro:false,
        evangelista:false,
        misionero:false,
        otro:false,
        id_estado_civil:0,
        oficio:'',
        desc_ministerio:'',
        nombre_pst_resp:'',
        contacto_pst_resp:'',
        nombre_igl_resp:'',
        contacto_igl_resp:'',
        id_nacionalidad:0,
        id_region:usuario.id_region,
        id_rango:0,
        rango:'',
        id_tipo_doc:0,
        nro_documento:''
    })

    useEffect(()=>{
        confirmarCargandoVista(true)
    },[])

    useEffect(()=>{

        const cargarTablasGenerales = async ()=>{

            setCargandoTablasGenerales(true);
        
            try{
                const vectorResultado = await Promise.all([
                    Axios.get('/api/tablasgenerales/nacionalidades'),
                    Axios.get('/api/tablasgenerales/estadosciviles'),
                    Axios.get('/api/tablasgenerales/regiones'),
                    Axios.get('/api/tablasgenerales/rangos'),
                    Axios.get('/api/tablasgenerales/provincias'),
                    Axios.get('/api/tablasgenerales/tiposdocumento'),    
                    Axios.get(`/api/tablasgenerales/iglesiasobrero/${id_obrero ? id_obrero : 0}`),
                    Axios.get(`/api/tablasgenerales/foto/${id_obrero ? id_obrero : 0}`),
                ])

               
                setNacionalidades(vectorResultado[0].data);
                setEstadosCiviles(vectorResultado[1].data);
                setRegiones(vectorResultado[2].data);
                setRangos(vectorResultado[3].data);
                parseData(vectorResultado[4].data);
                setTiposDocumentos(vectorResultado[5].data);
                setIglesias(vectorResultado[6].data);
                cargarVectorDias(setVectorDias);
                cargarVectorMeses(setVectorMeses);
                cargarVectorAnios(setVectorAnios);
                setFoto(vectorResultado[7].data);

                setCargandoTablasGenerales(false); 
                setTablasCargadas(true)
            }catch(err){
        
                    console.log(err)
                   // const mensaje_html = `<p>La busqueda de tablas generales falló</p><p>${err.response.data.message}</p>`
                    const mensaje_html = `${err}`

                    Swal.fire({
                        html:mensaje_html,
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                    })   
                    setHuboError(true)
                    setCargandoTablasGenerales(false);
    
                }
            }

            cargarTablasGenerales()
            ocultarMenu()
     },[id_obrero])

     useEffect(()=>{ // procesa los cierres de modales por boton cerrar y no por otro motivo
        if (!isShowing){
           
            if (iglesiaSeleccionada){
                setIglesiaSeleccionada(null)
            }        
        }
    },[isShowing])

useEffect(()=>{

    const completarDatosDelObrero = async (id)=>{   
        setCargandoDatosObrero(true)
        try{
            
                const {data} = await Axios.get(`/api/tablasgenerales/obrero/${id}`)

                if (!data) {
                    const mensaje_html = `<p>No se encontraron datos para el ministro ${id}</p>`
    
                    Swal.fire({
                        html:mensaje_html,
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                    })   

                    setCargandoDatosObrero(false)
                    setHuboError(true)
                    return
                }

                const datosDelRecordset = data;

                const datosObrero = {
                    id_provincia:noNullNumber(datosDelRecordset.id_provincia),
                    nombre:datosDelRecordset.nombre.trim(),
                    fecha:datosDelRecordset.f_nac,
                    anio:datosDelRecordset.f_nac.slice(0,4),
                    dia:datosDelRecordset.f_nac.slice(8,10),
                    mes:Number(datosDelRecordset.f_nac.slice(5,7)),
                    direccion:noNull(datosDelRecordset.direccion),
                    localidad:noNull(datosDelRecordset.localidad),
                    cod_postal:noNull(datosDelRecordset.cod_postal),
                    email:noNull(datosDelRecordset.email),
                    telefono:noNull(datosDelRecordset.telefono),
                    celular:noNull(datosDelRecordset.celular),
                    barrio:noNull(datosDelRecordset.barrio),
                    id_estado_civil:noNullNumber(datosDelRecordset.id_estado_civil),
                    id_nacionalidad:noNullNumber(datosDelRecordset.id_nacionalidad), 
                    oficio:noNull(datosDelRecordset.oficio),
                    desc_ministerio:noNull(datosDelRecordset.desc_ministerio),
                    conyuge:noNull(datosDelRecordset.conyuge),
                    nombre_pst_resp:noNull(datosDelRecordset.nombre_pst_resp),
                    contacto_pst_resp:noNull(datosDelRecordset.contacto_pst_resp),
                    nombre_igl_resp:noNull(datosDelRecordset.nombre_igl_resp),
                    contacto_igl_resp:noNull(datosDelRecordset.contacto_igl_resp),
                    sexo:noNull(datosDelRecordset.sexo),
                    id_region:noNullNumber(datosDelRecordset.id_region),
                    id_rango:noNullNumber(datosDelRecordset.id_rango),
                    region:noNull(datosDelRecordset.region),
                    pastor:datosDelRecordset.pastor,
                    maestro:datosDelRecordset.maestro,
                    evangelista:datosDelRecordset.evangelista,
                    misionero:datosDelRecordset.misionero,
                    otro:datosDelRecordset.otro,
                    nro_documento:noNullNumber(datosDelRecordset.nro_documento),
                    id_tipo_doc : noNullNumber(datosDelRecordset.id_tipo_doc),                    
                }
                  
                console.log('datosObrero',datosObrero)
                //se actualiza el objeto  de inicializacion con lo que traemos de la tabla
                // se hace un merge de los datos, los que son comunes se pisan y los nuevos se agregan

                
                setObjetoInicializacion({...objetoInicializacion,...datosObrero}) 

                setDatosParaImpresiones(datosDelRecordset)
                
                if(datosDelRecordset?.foto_4x4){
                    const fotoaux = await traerUrl(datosDelRecordset?.foto_4x4)
                    setFoto4x4(fotoaux)
                }
                //setContadorOperaciones(contadorOperaciones+1); // modifico contadorOperaciones para que se dispare el effect que busca materias e instrumentos una vez que se hayan cargado primero los datos del alumno. De esta forma ordeno secuencialmente la carga de datos y evito el warning de react "Can't perform a React state update on an unmounted component"
                setCargandoDatosObrero(false)
                confirmarCargandoVista(false)
                // bugsol 1
                // return datosDelRecordset // retorno un valor para que pueda hacerse algo en el .then ya que al ser async devuelva una promesa
            }catch(err){

                console.log(err)
               // const mensaje_html = `<p>La busqueda de datos del alumno falló</p><p>${err.response.data.message}</p>`
                const mensaje_html = `${err}`
                Swal.fire({
                    html:mensaje_html,
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                })   
            
                setCargandoDatosObrero(false)
                confirmarCargandoVista(false)
                setHuboError(true)
            }

    }

    if (tablasCargadas ){ // este useEffect se dispara solo si ya se cargaron las tablas generales

        if (id_obrero){ //  si se recibió el nùmero de alumno por propiedad es decir si es una modificación
            
           // setTituloAbm(`Editar mis datos`);
            setTituloAbm(``);
            setTituloCerrar('Cerrar la ficha del ministro');
            completarDatosDelObrero(id_obrero); 
            
        }
        else if (id_copia){
            setTituloAbm(`Crear un ministro como copia del ministro #${id_copia}`);
            setTituloCerrar('Cerrar la ficha del ministro');
            completarDatosDelObrero(id_copia); 
            const rangoPermitidoAlta = filtrarRangoPermitidoParaAlta(rangos,setRangos)
        }
        else{ //  si no recibió el nùmero de curso por propiedad, es decir un alta
            setTituloAbm(`Crear un nuevo ministro`);
            setTituloCerrar('Cancelar');
            hacerScroll("nuevo-obrero");
            const rangoPermitidoAlta = filtrarRangoPermitidoParaAlta(rangos,setRangos)
            const regionPermitidaAlta = filtrarRegionPermitidaParaAlta(regiones,setRegiones,usuario.id_region)
            let anioNacimientoDefaultAlta=anioNacimientoAlta();

            setObjetoInicializacion({...objetoInicializacion,
                                     anio:anioNacimientoDefaultAlta,
                                     id_rango:rangoPermitidoAlta,
                                     id_region:regionPermitidaAlta }) 
            document.getElementById('abm-nombre').focus()

        }

    }

},[tablasCargadas,id_obrero,contadorModificaciones])     

const parseData = (data) => {
  const parsedData = [];
  data.forEach((item) => { 
      const name = item.nombre.split(' (')[0];
      if (!parsedData.find((p) => p.nombre === name)) {
          parsedData.push(item);
          return;
      }
  });

  setProvincias(parsedData);
}

const handleInputChanges = async (type, value) => { 
  let formattedValue = value;
  setObjetoInicializacion({ ...objetoInicializacion, [type]: formattedValue });
} 

const buscarAlgoDelUsuario = async ()=>{

    return true
    /*try{
        setCargandoMateriasInstrumentos(true)
        const vectorResultado = await Promise.all([Axios.get(`/api/alumnos/materiastest/${id_alumno}`),
                                                Axios.get(`/api/alumnos/instrumentos/${id_alumno}`),
                                                Axios.get(`/api/alumnos/historial/${id_alumno}/1`)])
    

        if (vectorResultado[1].data.some(item=>item.id_instrumento>1))
        {
            setInstrumentosAlumno(vectorResultado[1].data)
            setBackupInstrumentosAlumno(vectorResultado[1].data)
        }            

        setMateriasTestAlumno(vectorResultado[0].data)
        setBackupMateriasTestAlumno(vectorResultado[0].data)

        setHistorial(vectorResultado[2].data)

        setCargandoMateriasInstrumentos(false)
        setHuboCambiosInstrumentos(false)
        setHuboCambiosMaterias(false)

        return(true)

    }catch(err){
        console.log(err)
        //const mensaje_html = `<p>La busqueda de instrumentos del alumno y materias aprobadas por test falló</p><p>${err.response.data.message}</p>`
        const mensaje_html = 'ddd'
        Swal.fire({
            html:mensaje_html,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
        })   
    
        setCargandoMateriasInstrumentos(false)
        setHuboError(true)
    }*/
}

const grabarObrero = async (values) => {

    let resultado;
    let id_obrero_interno;
    // me veo ogligado formatear el objeto que envío para grabar porque
    // los ids deben ser numéricos ya que se validan como números pero cuando el valor
    // viene de un "value" de un select por ejemplo se convierten a string
    // entonces antes de enviar el objeto hay que formatearlo

    const objetoAgrabar = { 
                nombre: values.nombre.trim(),
                anio:Number(values.anio),
                mes:Number(values.mes),
                dia:Number(values.dia),
                direccion:values.direccion.trim(),
                barrio:values.barrio.trim(),
                localidad:values.localidad.trim(),
                cod_postal:values.cod_postal.trim(),
                celular:values.celular.trim(),
                telefono:values.telefono.trim(),
                email:values.email.trim(),
                oficio:values.oficio.trim(),
                id_provincia:Number(values.id_provincia),
                id_estado_civil:Number(values.id_estado_civil),
                id_nacionalidad:Number(values.id_nacionalidad),                
                id_region:Number(usuario.id_region),   // en un alta de un obrero solo dejamos que se le asigne la región del usuario logueado             
                desc_ministerio:values.desc_ministerio.trim(),
                conyuge:values.conyuge.trim(),
                nombre_pst_resp:values.nombre_pst_resp.trim(),
                contacto_pst_resp:values.contacto_pst_resp.trim(),
                nombre_igl_resp:values.nombre_igl_resp.trim(),
                contacto_igl_resp:values.contacto_igl_resp.trim(),
                sexo:values.sexo.trim(),
                pastor:values.pastor,
                maestro:values.maestro,
                evangelista:values.evangelista,
                misionero:values.misionero,
                otro:values.otro,
                id_rango:values.id_rango,
                id_tipo_doc:Number(values.id_tipo_doc),
                id_usuario:usuario.id_obrero,
                nro_documento:values.nro_documento!="" ? Number(values.nro_documento) : 0
        }

    setGrabandoDatosObrero(true)

    let mensaje_html = `<p>Los datos se grabaron exitosamente</p>`

    try{
        if (id_obrero){
            resultado= await Axios.put(`/api/tablasgenerales/obrero/${id_obrero}`,objetoAgrabar)
            id_obrero_interno = id_obrero; // es el id del id_obrero a modificar
        }else{
            resultado= await Axios.post(`/api/tablasgenerales/obrero`,objetoAgrabar)
            id_obrero_interno = resultado.data.id_obrero; // es el id del nuevo obrero 
            mensaje_html = `<p>Los datos se grabaron exitosamente</p><p>(Nuevo ministro #${resultado.data.id_obrero})</p>`
        }

        Swal.fire({
            html:mensaje_html,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
        })   

        setGrabandoDatosObrero(false)

        if (esModal){
            if(id_obrero){ // si es modal y es una modificación
                setContadorModificaciones(contadorModificaciones+1)
                finalizarAltaOcopia(false)
            }else{ // si es modal y es un alta
                finalizarAltaOcopia(true,id_obrero_interno)
            }

        }else{ // si no es modal
            finalizarAltaOcopia(true); // es una función que se ejecuta en el padre para ejecutar una acción luego de haber creado o copiado un curso
        }

    }catch(err){
        console.log(err.response)
        let mensaje_html_error;

        if(err.response.data.message){
            mensaje_html_error = `<p>Se produjo un error al grabar los datos del obrero</p><p>${err.response.data.message}</p>`
        }else if (err.response.data) {
            mensaje_html_error = `<p>Se produjo un error al grabar los datos del obrero</p><p>${err.response.data}</p>`
        }else{
            mensaje_html_error = `<p>Se produjo un error al grabar los datos del obrero</p><p>${err.response}</p>`
        }


        Swal.fire({
            html:mensaje_html_error,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
        })   
    
        setGrabandoDatosObrero(false)
    }
   

}

const cerrarModalIglesia = ()=>{
    setIglesiaSeleccionada(null)
    toggle()
}

const iniciarGrabarObrero = (values)=>{
    let texto;
    let textoConfirmacion;

    if (id_obrero){
        texto = `Confirma la modificación de los datos?`
        textoConfirmacion = 'Si, modificar'
    }else{
        texto = `Confirma la creación del nuevo obrero? (${values.nombre})`
        textoConfirmacion = 'Si, crear el obrero'
    }

    Swal.fire({
        text:texto,
        showCancelButton:true,
        confirButtonText:textoConfirmacion,
        cancelButtonText:'Cancelar'
    }).then(
        resultado=>{
            if (resultado.value){
                grabarObrero(values);

            }else{
                console.log("Se canceló la modificación o creación del obrero")
            }
        }
    )
}


// Se carga directamente al traer los datos del alumno
/*const initialValuesAlumno = {

} */ 

// es un objeto cuyas propiedades deben coincidir con el nombre
                              // de los Fields y con el nombre del validationSchema

// algunas entidades comienzan de 1 y otras aceptan el valor 0 por eso
// en algunos casos valido con .positive() para los que comienzan de 1, porque positive() excluye el cero
// en otros valido con min(0) para los que comienzan de 0                              
// el .test lo dejo como ejemplo para notar que se pueden hacer validaciones más específicas

const validationSchemaObrero = Yup.object({

nombre:Yup.string().max(100,'El nombre debe tener como máximo 100 caracteres')
        .required('Falta completar el nombre y apellido'),
conyuge:Yup.string().max(100,'El conyuge debe tener como máximo 100 caracteres'),
dia:Yup.number()
    .required('Falta seleccionar el día de nacimiento'),
mes:Yup.number()
    .required('Falta seleccionar el mes de nacimiento'),
anio:Yup.number()
    .min(1920,'El año no es válido')
    .required('Falta seleccionar el año de nacimiento'),        
direccion:Yup.string().max(500,'La dirección debe tener como máximo 500 caracteres'),            
localidad:Yup.string().max(500,'La localidad debe tener como máximo 500 caracteres')
    .required('Falta completar la localidad'),    
cod_postal:Yup.string().max(10,'El código postal debe tener como máximo 10 caracteres'), 
barrio:Yup.string().max(500,'El barrio debe tener como máximo 500 caracteres'),            
telefono:Yup.string().max(500,'El teléfono debe tener como máximo 500 caracteres'),
celular:Yup.string().max(100,'El número de celular debe tener como máximo 100 caracteres'),
email:Yup.string().email('El email no es válido').max(100,'El email debe tener como máximo 100 caracteres'),            
oficio:Yup.string().max(100,'El oficio debe tener como máximo 100 caracteres'),
desc_ministerio:Yup.string().max(200,'La descripción del ministerio debe tener como máximo 200 caracteres'),
nombre_pst_resp:Yup.string().max(300,'El nombre del pastor responsable debe tener como máximo 300 caracteres'),
contacto_pst_resp:Yup.string().max(500,'Los datos de contacto del pastor responsable deben tener como máximo 500 caracteres'),
nombre_igl_resp:Yup.string().max(300,'El nombre de la iglesia responsable debe tener como máximo 300 caracteres'),
contacto_igl_resp:Yup.string().max(500,'Los datos de contacto de la iglesia responsable deben tener como máximo 500 caracteres'),
pastor:Yup.boolean().required(),
maestro:Yup.boolean().required(),
evangelista:Yup.boolean().required(),
misionero:Yup.boolean().required(),
otro:Yup.boolean().required(),
id_region:Yup.number().integer().required(),
id_rango:Yup.number().integer().required(),
id_estado_civil:Yup.number()
    .integer()
    .required('Falta seleccionar el estado civil')
    .test("prueba","El estado civil del ministro debe ser mayor a o igual a cero",value => value >= 0),
id_provincia:Yup.number()
    .integer()
    .required('Falta seleccionar la provincia'),
nro_documento:Yup.number().typeError('El documento debe ser un número').min(1000000,'El documento debe tener como mínimo 7 dígitos')
    .max(99999999,'El documento debe tener como máximo 8 dígitos'),
id_tipo_doc:Yup.number('El documento debe ser un número')
    .integer()
    .required('Falta seleccionar el tipo de documento')
})                 

const onsubmitObrero = values =>{
    console.log(values)
    iniciarGrabarObrero(values)
}

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }

    if (cargandoDatosTablasGenerales || cargandoDatosObrero) {
        return (
            <Main center alinear>
                <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                    <Spinner className="h-12 w-12" />
                    <span className="cargando font-semibold text-[15px]">Cargando datos personales...</span>
                </div>
            </Main>
        )
    }

    return (
      <>
        <Typography className={`relative w-full text-[25px] font-semibold text-center mt-2 mb-6 ${isEdition ? 'pt-12 xl:pt-0' : ''}`}>
            <span className={`${isEdition ? '' : 'hidden'}`}>Editar </span>Datos Personales
          <ArrowLeftIcon 
            color='black' 
            width={22} 
            className={`${isEdition ? 'flex' : 'hidden'} absolute left-4 top-2 cursor-pointer hover:opacity-80`} 
            onClick={() => setIsEdition(false)}
          />
          <span 
            onClick={() => setIsEdition(false)}
            className={`${isEdition ? 'absolute left-11 top-2' : 'hidden'} text-[15px] !font-medium cursor-pointer hover:opacity-80`}
          >
            Regresar
          </span>
        </Typography>
        <div className={`${isEdition ? 'hidden' : 'flex'} w-[90%] lg:w-[66%] m-auto grid grid-flow-row-dense grid-cols-1 xl:grid-cols-2 lg:gap-3`}> 
            { grabandoDatosObrero && (
                <Main>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Grabando datos...</span>
                    </div>
                </Main>)}
        
            { isShowing && iglesiaSeleccionada && <Modal hide={toggle} isShowing={isShowing} estilo={{width:'1200px'}} estiloWrapper={{background:'transparent'}} closeOnclickOutside={true}>
                <AbmIglesias id_iglesia={iglesiaSeleccionada} usuario={usuario} finalizarAltaOcopia={cerrarModalIglesia} esVisualizacion={true}/>    
            </Modal>}
            

            <Card className='w-full !font-sans lg:min-h-[550px] xl:min-h-[528px] mb-2 lg:mb-0 relative'> 
              <div className="px-6 py-6">
                  {!foto4x4 && <div className="flex w-100pc f-col items-center justify-center f-movil-s">
                    {foto && <img className="mt-3 mr-auto ml-auto !rounded-md border-[2px] border-[#E0E0E0]" alt="Foto inexistente" width="120" src={`data:image/jpeg;base64,${foto}`} />}
                    {!foto && <FontAwesomeIcon style={{fontSize:'100px'}} icon={faUserCircle}/>}
                    {foto && <p className="text-[#111] mt-2 text-[19px]">{objetoInicializacion.nombre}</p>}
                    {!foto && <p className="color-gray">Foto no registrada en el sistema</p>}
                  </div>}
                  {foto4x4 && <div className="flex w-100pc f-col items-center justify-center f-movil-s">
                      <img className="p-2 mr-auto ml-auto" width="180"
                          src={foto4x4}
                          alt="Foto 4 x 4"
                      />
                  </div>}

                  <Button className="!absolute flex !bg-[#05AFF4] px-[5px] xl:px-[10px] py-[7px] m-auto !top-[5px] !right-[5px] z-30" size="small" onClick={() => setIsEdition(true) }>
                    <PencilIcon color='white' width={13} />
                    <p className="text-[11px] ml-1">Editar</p>
                  </Button>
                  <div className="w-[98%] h-[1px] bg-[#D0D0D0] rounded-md m-auto mb-3"></div>

                  <div className="flex f-col">
                    <div className="flex f-col mb-5">
                      <label className="Form__labels__abmcursos_corto !border-none text-[#111] font-semibold text-center">DETALLES</label>
                    </div>

                    <div className="sec-cond1 xmt-2 ic-sd"> 
                      <div className='w-full'>
                          <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                            <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                              <span className='mr-2 font-medium text-[#111]'>Sexo:</span>
                              {objetoInicializacion.sexo}
                            </p> 
                          </div>  
                          <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                            <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                              <span className='mr-2 font-medium text-[#111]'>Nacionalidad:</span>
                              {nacionalidades.find(item => item.id_nacionalidad === objetoInicializacion.id_nacionalidad)?.nombre ?? ''}
                            </p> 
                          </div>   
                          <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                            <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                              <span className='mr-2 font-medium text-[#111]'>Estado civil:</span>
                              {estadosCiviles.find(item => item.id_estado_civil === objetoInicializacion.id_estado_civil)?.nombre ?? ''}
                            </p> 
                          </div>
                          <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                            <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                              <span className='mr-2 font-medium text-[#111]'>Tipo documento:</span>
                              {tiposDocumentos.find(item => item.id_tipo_doc === objetoInicializacion.id_tipo_doc)?.nombre ?? ''}
                            </p> 
                          </div>   
                          <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                            <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                              <span className='mr-2 font-medium text-[#111]'>Nro. documento:</span>
                              {objetoInicializacion.nro_documento}
                            </p> 
                          </div>
                      </div>
                    </div>
                    <div className="sec-cond1 el-abm-sel-pos hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                      <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                        <span className='mr-2 font-medium text-[#111]'>Fecha de nacimiento:</span>
                        {`${objetoInicializacion.dia}/${objetoInicializacion.mes}/${objetoInicializacion.anio}`}
                      </p> 
                    </div>
                    <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                      <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                        <span className='mr-2 font-medium text-[#111]'>Cónyuge:</span>
                        {objetoInicializacion.conyuge || 'N/A'}
                      </p> 
                    </div>   
                  </div>  
              </div>
            </Card>

            <div className='w-full'> 
              <Card className='w-full px-6 py-6'> 
                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Dirección:</span>
                    {objetoInicializacion.direccion || 'N/A'}
                  </p> 
                </div>  

                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Barrio:</span>
                    {objetoInicializacion.barrio || 'N/A'}
                  </p> 
                </div> 

                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full"> 
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Localidad:</span>
                    {objetoInicializacion.localidad || 'N/A'}
                  </p> 
                </div> 

                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Código Postal:</span>
                    {objetoInicializacion.cod_posta || 'N/A'}
                  </p> 
                </div> 

                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Provincia:</span>
                    {provincias.find(item => item.id_provincia === objetoInicializacion.id_provincia)?.nombre ?? ''}
                  </p> 
                </div>  

                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full"> 
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Teléfono:</span>
                    {objetoInicializacion.telefono || 'N/A'}
                  </p> 
                </div>  

                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full"> 
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Celular:</span>
                    {objetoInicializacion.celular || 'N/A'}
                  </p> 
                </div> 

                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>E-mail:</span>
                    {objetoInicializacion.email || 'N/A'}
                  </p> 
                </div>

                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Oficio:</span>
                    {objetoInicializacion.oficio || 'N/A'}
                  </p>
                </div> 

                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Ministerio (Breve descripción):</span>
                    {objetoInicializacion.desc_ministerio || 'N/A'}
                  </p>
                </div> 
              </Card>

              <Card className='w-full mt-2 px-6 py-6 h-[190px] mb-4 lg:mb-0 xl:mb-0'> 
                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Pastor:</span>
                    {objetoInicializacion.pastor ? 'Sí' : 'No'}
                  </p> 
                </div>

                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Maestro:</span>
                    {objetoInicializacion.maestro ? 'Sí' : 'No'}
                  </p> 
                </div>

                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Evangelista:</span>
                    {objetoInicializacion.evangelista ? 'Sí' : 'No'}
                  </p> 
                </div>

                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Misionero:</span>
                    {objetoInicializacion.misionero ? 'Sí' : 'No'}
                  </p> 
                </div>

                <div className="flex f-col hover:bg-[#dae1e7]/60 hover:rounded-md w-full">
                  <p className="Form__labels__abmcursos_corto !border-none text-[15px]">
                    <span className='mr-2 font-medium text-[#111]'>Otro:</span>
                    {objetoInicializacion.otro ? 'Sí' : 'No'}
                  </p> 
                </div>
              </Card>
            </div>
        </div>

        <div className={`${isEdition ? 'flex' : 'hidden'}`}>
          <AbmObreroEditionForm 
            data={objetoInicializacion}
            handleInputChanges={handleInputChanges}
            nacionalidades={nacionalidades}
            estadosCiviles={estadosCiviles}
            tiposDocumentos={tiposDocumentos}
            provincias={provincias}
            grabarObrero={iniciarGrabarObrero}
          /> 
        </div>

      </>

    )
}

function cargarVectorHoras() {
    let hora;
    let vector_horas = []

    for (var i = 8; i < 23; i++) {
        if (i < 10) {
            hora = `0${i}`;
        } else {
            hora = `${i}`;
        }
        vector_horas.push(hora);
    }

    return vector_horas
}

function cargarCapacidades() {
    let capacidad;
    let vector_capacidad = []

    for (var i = 1; i < 100; i++) {
        vector_capacidad.push(i);
    }

    return vector_capacidad
}
function cargarVectorMinutos() {
    let vector_minutos = []

    vector_minutos.push('00');
    vector_minutos.push('30');

    return vector_minutos
}


function diferencia(horai,horaf,minutoi,minutof) {
    var resultado = true;
    var mensaje = '';

    var hora_desde = horai;
    var hora_hasta = horaf;
    var min_desde = minutoi;
    var min_hasta = minutof;

    var hora_desde_nummerica = Number(hora_desde + min_desde)
    var hora_hasta_nummerica = Number(hora_hasta + min_hasta)

    console.log('hora desde: ' + hora_desde_nummerica)
    console.log('hora hasta: ' + hora_hasta_nummerica)

    if (hora_desde_nummerica >= hora_hasta_nummerica) {
        resultado = false;
        mensaje = 'La hora de inicio debe ser anterior a la hora de fín'
    }

    console.log('hora_hasta_nummerica',hora_hasta_nummerica)
    console.log('hora_desde_nummerica',hora_desde_nummerica)

    return (hora_hasta_nummerica > hora_desde_nummerica  )

}

function hacerScroll(id){
    let element = document.getElementById(id);

    if(!element){return}
    element.scrollIntoView(false);
}

function cargarVectorDias(setDias) {
    var dia;
    var vectoDiasAux=[];

    for (var i = 1; i < 32; i++) {
        if (i < 10) {
            dia = `0${i}`;
        } else {
            dia = `${i}`;
        }
        vectoDiasAux.push(dia);
    }
    setDias(vectoDiasAux)
}

function  cargarVectorMeses(setMeses) {
    var meses = [{ id: 1, mes: 'Enero' },
    { id: 2, mes: 'Febrero' },
    { id: 3, mes: 'Marzo' },
    { id: 4, mes: 'Abril' },
    { id: 5, mes: 'Mayo' },
    { id: 6, mes: 'Junio' },
    { id: 7, mes: 'Julio' },
    { id: 8, mes: 'Agosto' },
    { id: 9, mes: 'Septiembre' },
    { id: 10, mes: 'Octubre' },
    { id: 11, mes: 'Noviembre' },
    { id: 12, mes: 'Diciembre' }];
    setMeses(meses);
}

function anioNacimientoAlta(){
    let fecha_actual = new Date();
    let anio_hasta = Number(fecha_actual.getFullYear() - 3);

    return anio_hasta
}

function cargarVectorAnios(setAnios) {
    var anios = [];
    var anio;

    var fecha_actual = new Date();
    var anio_hasta = Number(fecha_actual.getFullYear() - 10);
    var anio_desde = anio_hasta - 80;

    for (var i = anio_hasta; i > anio_desde; i--) {
        anio = i.toString();
        anios.push(anio);
    }

    anios.push(1900); // agrego porque en la tabla hay fechas vacias que sql server los transforma a una fecha nula 1900-01-01 00:00:00.000
                      // para que tome las fechas 1900-01-01 00:00:00.000 y que el usuario vea que es un año invalido 

    setAnios(anios);
}

function noNull(valor){
    if (!valor){
        return ''
    }else{
        return valor.trim()
    }
}

function noNullNumber(valor){
    if (!valor){
        return 0
    }else{
        return valor
    }
}



function filtrarRangoPermitidoParaAlta(rangos,setRangos){
    const rangosPermitidos = rangos.filter(item=>item.nombre.includes('sin credencial'))
    setRangos(rangosPermitidos)

    if (rangosPermitidos.length>0){
        return rangosPermitidos[0].id_rango
    }else{
        return null
    }
}

function filtrarRegionPermitidaParaAlta(regiones,setRegiones,id_region_usuario){
    const regionesPermitidas = regiones.filter(item=>item.id_region===id_region_usuario)
    setRegiones(regionesPermitidas)

    if (regionesPermitidas.length>0){
        return regionesPermitidas[0].id_region
    }else{
        return null
    }
}

function determinarFilas(palabra){
    if (palabra.length>69){
        return 2
    }else{
        return 1
    }

}

function CondicionIglesia({iglesia}){
    return <div className="flex f-row jfc-fe text-smaller mt-2">  
        <div className="ml-4"><span>Balances:</span>{ iglesia.estado_balances==1 && <span><FontAwesomeIcon className="mr-2 ml2 color-green" icon={faCheckCircle}/>Al día</span>}
             { iglesia.estado_balances==0 && <span><FontAwesomeIcon className="mr-2 ml2 color-red" icon={faTimesCircle}/>Adeuda</span>}
        </div>
        <div className="ml-4"><span>Diezmos:</span> {iglesia.detalle_diezmos==1 && <span><FontAwesomeIcon className="mr-2 ml2 color-green" icon={faCheckCircle}/>Al día</span>}
              {iglesia.detalle_diezmos==0 && <span><FontAwesomeIcon className="mr-2 ml2 color-red" icon={faTimesCircle}/>Adeuda</span>}
        </div>
    </div>
}

function OtrasIglesiasObrero({id_obrero, iglesiasAutonomas,seleccionarIglesia}){
    const [iglesias,setIglesias]=useState([]);
    const [buscandoDatos,setBuscandoDatos]=useState(false)
    const [huboError,setHuboError] =useState(false)

    useEffect(()=>{
        let mounted=true

        const buscarOtrasIglesias = async ()=>{
            try{
                setBuscandoDatos(true)
                const vectorResultados = await Promise.all([
                    Axios.get(`/api/tablasgenerales/iglesiasobreroall/${id_obrero ? id_obrero : 0}`),
                ])

                if (mounted){ // para evitar el warning can't perform...

                    const iglesiasNoAutonomas = excluirIglesiasAutonomas(vectorResultados[0].data,iglesiasAutonomas)
                    setIglesias(iglesiasNoAutonomas)
                    setBuscandoDatos(false)
                }

            }catch(error){
                setHuboError(true)
                setBuscandoDatos(false)
            }
        }

        buscarOtrasIglesias();

        return ()=>{mounted=false} // para evitar el warning can't perform...
    },[])

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }
    
    if (buscandoDatos){
        return <Main center><Loading/><span className="cargando">Buscando iglesias...</span></Main>
    }

    return <div className="flex f-col">
    <span className="p-2 mt-2 mb-2 text-white bg-tomato inline-block-1 text-center">Otras iglesias <span>({iglesias.length})</span></span>
        {iglesias.length >0 && 
            <div>
                {iglesias.map(item=><div className="diezmos mb-2"><span className="text-smaller mr-2 tipo-iglesia whitespace-no-wrap">{item.tipo_iglesia}</span><span className="border-bottom-dotted-gray cursor-pointer" onClick={()=>seleccionarIglesia(item.id_iglesia)}>{item.iglesia}</span><br />
                <FontAwesomeIcon className="mt-2 mr-2" icon={faMapMarkerAlt}/>
                <span className="text-smaller whitespace-no-wrap">{item.provincia}</span></div>)}
            </div>
        }
        {iglesias.length == 0 && 
            <div>
                <span className="diezmos">Ninguna</span>
            </div>
        }        

    {/*<div className="flex f-col text-large">

    <span className="inline-block1 text-larger fw-100 mb-2 mt-4">Otras iglesias asociadas al ministro</span>
               
            {iglesias.map(item=><div className="ig-min"><span className="border-bottom-dotted-gray">{item.iglesia}</span>
            <span className="border-bottom-dotted-gray">{item.provincia}</span>
            </div>)}
        </div> */}                     
    </div>

  }

  function excluirIglesiasAutonomas(todas,autonomas){

    const excluirAutonomas = todas.filter(item=>!autonomas.some(iglesia=>iglesia.cod_iglesia==item.cod_iglesia))

    return excluirAutonomas
}