import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle,faWindowClose,faUser } from '@fortawesome/free-regular-svg-icons';
import {faMapMarkerAlt,faChurch,faTrash,faUndo,faSearch,faPhone,faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { 
    Card, 
    Input, 
    Select, 
    Option, 
    Checkbox, 
    Button,
    Tabs,
    Tab,
    TabsHeader,
    Typography
} from "@material-tailwind/react";
import { Box } from '@material-ui/core';

const TABS_HEADER = ["Ministros", "Iglesias", "Dependientes"];

export default function Parametros({tipo,
    handleChangeTipo,
    activeTab,
    setActiveTab,
    iglesia,
    handleChangeIglesia,
    pastor,
    handleChangePastor,
    localidad,
    handleChangeLocalidad,
    ejecutarBusqueda,borrarPastor,borrarIglesia,borrarLocalidad,
    provincias,provincia,handleChangeProvincia,datos,contadorOperaciones,
    resetBusqueda, filtro, handleChangeFiltro,borrarFiltro,resultado}){

    return <div className={`${tipo == '' ? '' : ''}`}>
            <Typography variant="h4" className="flex justify-center mb-4">
                Nomina
            </Typography>  
            <Card className="w-[90%] lg:w-[65%] rounded ml-auto mr-auto px-3">
                <div className="nomina">
                    <div className="text-center mb-4 border-b-[1px] border-b-[#ddd] py-4">
                        <Tabs value={tipo}>
                            <TabsHeader 
                                className="overflow-x-auto"
                                indicatorProps={{
                                    className: "bg-[#05AFF4] shadow-none !text-white",
                                }}
                            >
                                {TABS_HEADER.map((item, index) => (
                                    <Tab 
                                        key={index} 
                                        value={item.toLowerCase()} 
                                        className={`text-[14px] ${tipo === item.toLowerCase() ? 'text-white' : ''}`}
                                        onClick={() => handleChangeTipo(item.toLowerCase(), item.toLowerCase())}
                                    >
                                        {item}
                                    </Tab>
                                ))}
                            </TabsHeader>
                        </Tabs>
                    </div>
                    { tipo!='dependientes' && <div className='flex flex-col lg:flex-row w-full justify-between px-2'>
                        <div className="flex flex-col mb-2">
                            <span className="w-full text-medium w-100 inline-block-1 fw-100 ley-s font-semibold text-center">{tipo=='iglesias' ? 'Pastor' : 'Ministro'}</span>
                            <Input 
                                type="text" 
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900 !h-[30px]"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                value={pastor.toUpperCase()} 
                                onChange={handleChangePastor}
                            />
                            {/*pastor!='' && <FontAwesomeIcon onClick={borrarPastor} icon={faTrash} className="cursor-pointer"/>*/}
                        </div>
                        {tipo==='iglesias' && <div className="flex flex-col mb-2">
                            <span className="w-full text-medium w-100 inline-block-1 fw-100 ley-s font-semibold text-center">Iglesia</span>
                            <Input 
                                type="text" 
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900 !h-[30px]"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                value={iglesia.toUpperCase()} 
                                onChange={handleChangeIglesia}
                            />
                            {/*iglesia!='' && <FontAwesomeIcon onClick={borrarIglesia} icon={faTrash} className="cursor-pointer"/>*/}
                        </div>}
                        <div className="flex flex-col mb-2">
                            <span className="w-full text-medium w-100 inline-block-1 fw-100 ley-s font-semibold text-center">Ubicación</span>
                            <Input 
                                type="text" 
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900 !h-[30px]"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                value={localidad.toUpperCase()} 
                                onChange={handleChangeLocalidad}
                            />
                            {/*localidad!='' && <FontAwesomeIcon onClick={borrarLocalidad} icon={faTrash} className="cursor-pointer"/>*/}
                        </div>
                        
                        <div className='flex flex-col select-personal-data'>
                            <span className="w-full text-medium w-100 inline-block-1 fw-100 ley-s font-semibold text-center">Provincia</span>
                            <Select 
                                value={provincia} 
                                className='!h-[30px]'
                                onChange={(e)=> { handleChangeProvincia(e)}}
                            >
                                {provincias.map(item => (
                                        <Option key={`parametros-${item.id_provincia}`} value={item.id_provincia}>{item.nombre}</Option>
                                    )
                                )}
                            </Select>
                        </div>
                    </div>}
                    {tipo!='dependientes' && <div className="flex mb-4">
                            <Box className='w-full lg:w-[70%] xl:w-[70%] m-auto' sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                            {((pastor || localidad || iglesia || provincia!=-1) || resultado > 0) && <Button className='flex justify-center border-solid hover:bg-black/70 border-[2px] border-gray-700 w-full' onClick={resetBusqueda} >
                                    <FontAwesomeIcon className="mr-2 mt-0.5" icon={faWindowClose}/>Cancelar
                                </Button> }
                                <Button variant='outlined' className='flex justify-center bg-blue-400 text-white w-full' onClick={ejecutarBusqueda}><FontAwesomeIcon className="mr-2 mt-0.5" icon={faSearch}/>Buscar</Button>
                            </Box>
                    </div>}

                    {contadorOperaciones > 0 && 
                        <div className='relative top-2'> 
                            <p className="!bg-blue-gray-50 text-center resultados-n font-semibold !text-[#111]">
                                {`${tipo=='iglesias' ? 'Iglesias encontradas: ' : tipo=='dependientes' ? 'Dependientes encontrados:' :'Ministros encontrados: '} 
                                ${datos.length}`}
                            </p>
                            {resultado > 0 && <div className="mb-2 mt-2">
                                <input className="nomina" title="Filtre el listado ingresando una palabra clave" type="text" value={filtro.toUpperCase()} onChange={handleChangeFiltro}/>
                                {filtro!='' && <FontAwesomeIcon title="Borrar filtro" onClick={borrarFiltro} icon={faTrash} className="cursor-pointer color-tomato ml-2"/>}
                            </div>}
                    </div>
                    }
                </div>
            </Card>
        </div>

}