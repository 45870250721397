import React from 'react';
import {useState, useEffect} from 'react';
import Main from '../../componentes/Main';
import Axios from 'axios';
import Loading from '../../componentes/Loading';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { faCheckCircle,faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Datepicker from "react-tailwindcss-datepicker"; 
import {
  Select,
  Option, 
  Card, 
  Spinner, 
  Button, 
  Typography, 
  Input
} from "@material-tailwind/react";
import useModal from '../../hooks/useModal';
import { PencilIcon } from '@heroicons/react/24/outline';

export default function AbmIglesiaEditionForm({ 
  data,
  provincias,
  handleInputChanges,
  grabarIglesia
}){
    // estados flags 
    const [cargandoDatosTablasGenerales,setCargandoTablasGenerales] = useState(false);
    const [cargandoDatosObrero,setCargandoDatosObrero] = useState(false);
    const [huboError,setHuboError]=useState(false)
    const [iglesiaSeleccionada,setIglesiaSeleccionada]= useState(null);

    // Variables para manejar otras operaciones
    const [date, setDate] = useState({ 
        startDate: null, 
        endDate: null 
    });

    const {toggle, isShowing } = useModal();

    useEffect(() => { 
        if (data.dia && data.mes && data.anio) { 
            const momentDate = moment(`${data.dia.toString()}/${data.mes < 10 && data.mes.toString().length === 1 ? '0'+data.mes.toString() : data.mes.toString()}/${data.anio.toString()}`, 'DD/MM/YYYY').toDate();
            const currentDate = new Date(momentDate)
            setDate({ ...date, startDate: new Date(currentDate) })
        }
    },[])   

    useEffect(()=>{ // procesa los cierres de modales por boton cerrar y no por otro motivo
      if (!isShowing){
        if (iglesiaSeleccionada){
          setIglesiaSeleccionada(null)
        }        
      }
    },[isShowing])   

const handleValueChange = (newValue) => {
    const myDate = moment(newValue.startDate, 'YYYY/MM/DD');
    handleInputChanges('dia', myDate.format('DD'));
    handleInputChanges('mes', myDate.format('MM'));
    handleInputChanges('anio', myDate.format('YYYY'));
    setDate(newValue); 
}   

  if (huboError){
      return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
  }

  if (cargandoDatosTablasGenerales || cargandoDatosObrero) {
      return (
          <Main center alinear>
              <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                  <Spinner className="h-12 w-12" />
                  <span className="cargando font-semibold text-[15px]">Cargando datos personales...</span>
              </div>
          </Main>
      )
  }

    return (
      <Card className={`w-[90%] lg:w-[66%] m-auto px-6 py-8 xl:grid xl:grid-flow-row-dense xl:grid-cols-3 xl:gap-3`}>
        <div className='flex flex-col mb-3'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            Nombre
          </Typography>
          <Input
            value={data.nombre_iglesia}
            placeholder={'Nombre'}
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            onChange={(e) => handleInputChanges('nombre_iglesia', e.target.value)}
          /> 
        </div>

        <div className='flex flex-col select-personal-data mb-3'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            Dirección
          </Typography>
          <Input
            value={data.direccion}
            placeholder={'Dirección'}
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            onChange={(e) => handleInputChanges('direccion', e.target.value)}
          /> 
        </div>

        <div className='flex flex-col select-personal-data mb-3'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            Barrio
          </Typography>
          <Input
            value={data.barrio}
            placeholder={'Barrio'}
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            onChange={(e) => handleInputChanges('barrio', e.target.value)}
          /> 
        </div>

        <div className='flex flex-col select-personal-data mb-3'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            Localidad
          </Typography>
          <Input
            value={data.localidad}
            placeholder={'Localidad'}
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            onChange={(e) => handleInputChanges('localidad', e.target.value)}
          /> 
        </div>

        <div className='flex flex-col select-personal-data mb-3'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            Código Postal
          </Typography>
          <Input
            value={data.cod_postal}
            placeholder={'Código Postal'}
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            onChange={(e) => handleInputChanges('cod_postal', e.target.value)}
          /> 
        </div>

        <div className='flex flex-col select-personal-data mb-3'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            Provincia
          </Typography>
          <Select 
            value={data.id_provincia}
            onChange={(e) => handleInputChanges('id_provincia', e)}
          >
            {
              provincias.map(item => (
                  <Option key={`abmcurso-permiso${item.id_provincia}`} value={item.id_provincia}>{item.nombre}</Option>
                )
              )
            }
          </Select>
        </div>

        <div className='flex flex-col mb-3'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            Teléfono
          </Typography>
            <Input
              value={data.telefono}
              placeholder={'Teléfono'}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              onChange={(e) => handleInputChanges('telefono', e.target.value)}
            /> 
        </div>

        <div className='flex flex-col edition-date-picker mb-3'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            Fax
          </Typography>
          <Input
            value={data.fax}
            placeholder={'Fax'}
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            onChange={(e) => handleInputChanges('fax', e.target.value)}
          /> 
        </div>

        <div className='flex flex-col mb-3'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            Sitio web
          </Typography>
          <Input
            value={data.pag_web}
            placeholder={'Sitio web'}
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            onChange={(e) => handleInputChanges('pag_web', e.target.value)}
          /> 
        </div>

        <div className='flex flex-col mb-3 select-personal-data'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            Cantidad de miembros
          </Typography>
          <Input
            value={data.cant_miembros}
            type='number'
            placeholder={'Cantidad de miembros'}
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            onChange={(e) => handleInputChanges('cant_miembros', e.target.value)}
          /> 
        </div>

        <div className='flex flex-col mb-3 select-personal-data'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            ¿Tiene fichero de culto?
          </Typography>
          <Select 
            value={data.fich_culto ? 'SI' : 'NO'}
            onChange={(e) => handleInputChanges('fich_culto', e === 'SI' ? 1 : 0)}
          >
            <Option value='SI'>Si</Option>
            <Option value='NO'>No</Option>
          </Select>
        </div>

        <div className='flex flex-col mb-3 select-personal-data'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            ¿Lleva libros contables?
          </Typography>
          <Select 
            value={data.libro_cont ? 'SI' : 'NO'}
            onChange={(e) => handleInputChanges('libro_cont', e === 'SI' ? 1 : 0)}
          >
            <Option value='SI'>Si</Option>
            <Option value='NO'>No</Option>
          </Select>
        </div>

        <div className='flex flex-col mb-3 select-personal-data'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            ¿Posee algún tipo de seguro?
          </Typography>
          <Select 
            value={data.seguro ? 'SI' : 'NO'}
            onChange={(e) => handleInputChanges('seguro', e === 'SI' ? 1 : 0)}
          >
            <Option value='SI'>Si</Option>
            <Option value='NO'>No</Option>
          </Select>
        </div>

        <div className='flex flex-col select-personal-data mb-3'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            ¿Tiene habilitación municipal?
          </Typography>
          <Select 
            value={data.hab_municip ? 'SI' : 'NO'}
            onChange={(e) => handleInputChanges('hab_municip', e === 'SI' ? 1 : 0)}
          >
            <Option value='SI'>Si</Option>
            <Option value='NO'>No</Option>
          </Select>
        </div>

        <div className='flex flex-col mb-3 select-personal-data'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            ¿Posee algún dispensario a cargo?
          </Typography>
          <Select 
            value={data.dispensario ? 'SI' : 'NO'}
            onChange={(e) => handleInputChanges('dispensario', e === 'SI' ? 1 : 0)}
          >
            <Option value='SI'>Si</Option>
            <Option value='NO'>No</Option>
          </Select>
        </div>

        <div className='flex flex-col mb-3 select-personal-data'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            ¿Posee algún comedor a cargo?
          </Typography>
          <Select 
            value={data.comedor ? 'SI' : 'NO'}
            onChange={(e) => handleInputChanges('comedor', e === 'SI' ? 1 : 0)}
          >
            <Option value='SI'>Si</Option>
            <Option value='NO'>No</Option>
          </Select>
        </div>

        <div className='flex flex-col mb-3 select-personal-data'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            ¿Posee algún colegio a cargo?
          </Typography>
          <Select 
            value={data.colegio ? 'SI' : 'NO'}
            onChange={(e) => handleInputChanges('colegio', e === 'SI' ? 1 : 0)}
          >
            <Option value='SI'>Si</Option>
            <Option value='NO'>No</Option>
          </Select>
        </div>

        <div className='flex flex-col mb-3 select-personal-data'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            ¿Posee algún centro de rehabilitación?
          </Typography>
          <Select 
            value={data.rehabilit ? 'SI' : 'NO'}
            onChange={(e) => handleInputChanges('rehabilit', e === 'SI' ? 1 : 0)}
          >
            <Option value='SI'>Si</Option>
            <Option value='NO'>No</Option>
          </Select>
        </div>

        <div className='flex flex-col mb-3 select-personal-data'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            ¿Posee algún hogar de niños a cargo?
          </Typography>
          <Select 
            value={data.hogar_niños ? 'SI' : 'NO'}
            onChange={(e) => handleInputChanges('hogar_niños', e === 'SI' ? 1 : 0)}
          >
            <Option value='SI'>Si</Option>
            <Option value='NO'>No</Option>
          </Select>
        </div>

        <div className='flex flex-col mb-3 select-personal-data'>
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-medium"
          >
            ¿Posee algún predio recreativo?
          </Typography>
          <Select 
            value={data.predio_recreat ? 'SI' : 'NO'}
            onChange={(e) => handleInputChanges('predio_recreat', e === 'SI' ? 1 : 0)}
          >
            <Option value='SI'>Si</Option>
            <Option value='NO'>No</Option>
          </Select>
        </div>

        <div className='flex flex-col col-span-3'>
            <Button 
                className="flex !bg-[#05AFF4] w-[100px] m-auto mb-6" 
                size="small" 
                onClick={() => grabarIglesia(data) }
            >
                <PencilIcon color='white' width={22} className='relative left-[-4px] top-[1px]' />
                <p className="">Editar</p>
            </Button>
        </div>
      </Card>
    )
}

function cargarCapacidades() {
    let capacidad;
    let vector_capacidad = []

    for (var i = 1; i < 100; i++) {
        vector_capacidad.push(i);
    }

    return vector_capacidad
}


function diferencia(horai,horaf,minutoi,minutof) {
    var resultado = true;
    var mensaje = '';

    var hora_desde = horai;
    var hora_hasta = horaf;
    var min_desde = minutoi;
    var min_hasta = minutof;

    var hora_desde_nummerica = Number(hora_desde + min_desde)
    var hora_hasta_nummerica = Number(hora_hasta + min_hasta)

    console.log('hora desde: ' + hora_desde_nummerica)
    console.log('hora hasta: ' + hora_hasta_nummerica)

    if (hora_desde_nummerica >= hora_hasta_nummerica) {
        resultado = false;
        mensaje = 'La hora de inicio debe ser anterior a la hora de fín'
    }

    console.log('hora_hasta_nummerica',hora_hasta_nummerica)
    console.log('hora_desde_nummerica',hora_desde_nummerica)

    return (hora_hasta_nummerica > hora_desde_nummerica  )

}

function hacerScroll(id){
    let element = document.getElementById(id);

    if(!element){return}
    element.scrollIntoView(false);
}

function filtrarRangoPermitidoParaAlta(rangos,setRangos){
    const rangosPermitidos = rangos.filter(item=>item.nombre.includes('sin credencial'))
    setRangos(rangosPermitidos)

    if (rangosPermitidos.length>0){
        return rangosPermitidos[0].id_rango
    }else{
        return null
    }
}

function filtrarRegionPermitidaParaAlta(regiones,setRegiones,id_region_usuario){
    const regionesPermitidas = regiones.filter(item=>item.id_region===id_region_usuario)
    setRegiones(regionesPermitidas)

    if (regionesPermitidas.length>0){
        return regionesPermitidas[0].id_region
    }else{
        return null
    }
}

function CondicionIglesia({iglesia}){
    return <div className="flex f-row jfc-fe text-smaller mt-2">  
        <div className="ml-4"><span>Balances:</span>{ iglesia.estado_balances==1 && <span><FontAwesomeIcon className="mr-2 ml2 color-green" icon={faCheckCircle}/>Al día</span>}
             { iglesia.estado_balances==0 && <span><FontAwesomeIcon className="mr-2 ml2 color-red" icon={faTimesCircle}/>Adeuda</span>}
        </div>
        <div className="ml-4"><span>Diezmos:</span> {iglesia.detalle_diezmos==1 && <span><FontAwesomeIcon className="mr-2 ml2 color-green" icon={faCheckCircle}/>Al día</span>}
              {iglesia.detalle_diezmos==0 && <span><FontAwesomeIcon className="mr-2 ml2 color-red" icon={faTimesCircle}/>Adeuda</span>}
        </div>
    </div>
}

function OtrasIglesiasObrero({id_obrero, iglesiasAutonomas,seleccionarIglesia}){
    const [iglesias,setIglesias]=useState([]);
    const [buscandoDatos,setBuscandoDatos]=useState(false)
    const [huboError,setHuboError] =useState(false)

    useEffect(()=>{
        let mounted=true

        const buscarOtrasIglesias = async ()=>{
            try{
                setBuscandoDatos(true)
                const vectorResultados = await Promise.all([
                    Axios.get(`/api/tablasgenerales/iglesiasobreroall/${id_obrero ? id_obrero : 0}`),
                ])

                if (mounted){ // para evitar el warning can't perform...

                    const iglesiasNoAutonomas = excluirIglesiasAutonomas(vectorResultados[0].data,iglesiasAutonomas)
                    setIglesias(iglesiasNoAutonomas)
                    setBuscandoDatos(false)
                }

            }catch(error){
                setHuboError(true)
                setBuscandoDatos(false)
            }
        }

        buscarOtrasIglesias();

        return ()=>{mounted=false} // para evitar el warning can't perform...
    },[])

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }
    
    if (buscandoDatos){
        return <Main center><Loading/><span className="cargando">Buscando iglesias...</span></Main>
    }

    return <div className="flex f-col">
    <span className="p-2 mt-2 mb-2 text-white bg-tomato inline-block-1 text-center">Otras iglesias <span>({iglesias.length})</span></span>
        {iglesias.length >0 && 
            <div>
                {iglesias.map(item=><div className="diezmos mb-2"><span className="text-smaller mr-2 tipo-iglesia whitespace-no-wrap">{item.tipo_iglesia}</span><span className="border-bottom-dotted-gray cursor-pointer" onClick={()=>seleccionarIglesia(item.id_iglesia)}>{item.iglesia}</span><br />
                <FontAwesomeIcon className="mt-2 mr-2" icon={faMapMarkerAlt}/>
                <span className="text-smaller whitespace-no-wrap">{item.provincia}</span></div>)}
            </div>
        }
        {iglesias.length == 0 && 
            <div>
                <span className="diezmos">Ninguna</span>
            </div>
        }        

    {/*<div className="flex f-col text-large">

    <span className="inline-block1 text-larger fw-100 mb-2 mt-4">Otras iglesias asociadas al ministro</span>
               
            {iglesias.map(item=><div className="ig-min"><span className="border-bottom-dotted-gray">{item.iglesia}</span>
            <span className="border-bottom-dotted-gray">{item.provincia}</span>
            </div>)}
        </div> */}                     
    </div>

  }

  function excluirIglesiasAutonomas(todas,autonomas){

    const excluirAutonomas = todas.filter(item=>!autonomas.some(iglesia=>iglesia.cod_iglesia==item.cod_iglesia))

    return excluirAutonomas
}