import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import Main from '../componentes/Main';
import Qrcode from 'qrcode.react';
import Axios from 'axios';
import {useAlumno} from '../Context/alumnoContext';
import { 
    Card, 
    List, 
    ListItem, 
    Spinner, 
    Typography, 
    ListItemPrefix
} from "@material-tailwind/react";
import {
    DocumentArrowDownIcon
} from "@heroicons/react/24/solid";

export default function Qr ({ocultar,usuario}){

const [url,setUrl] = useState(null);
const [archivos, setArchivos]= useState([])
const [status, setStatus]= useState(false)
const [buscandoDatos,setBuscandoDatos] = useState(false)
const [hoveredIcon, setHoveredIcon] = useState('')
const {confirmarCargandoVista} = useAlumno()


useEffect(()=>{
    const obtenerArchivos = async ()=>{

        setBuscandoDatos(true);
        confirmarCargandoVista(true);

        try{
            const vectorResultado = await Promise.all([Axios.get(`/api/tablasgenerales/documentos`)]) 
            setArchivos(vectorResultado[0].data.documentos)
            setBuscandoDatos(false);
            confirmarCargandoVista(false)
        }catch(err){
            console.log(err)
            setBuscandoDatos(false);
            confirmarCargandoVista(false)
        }
    }

    obtenerArchivos()       

},[])

    const onHoverIcon = (key) => {
        setHoveredIcon(key)
    }

    const onHoverLeaveIcon = () => {
        setHoveredIcon('')
    }

    if(buscandoDatos){
        return (
            <Main center alinear>
                <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                    <Spinner className="h-12 w-12" />
                    <span className="cargando font-semibold text-[15px]">Buscando datos...</span>
                </div>
            </Main>
        )
    }

    return (
        <>
            <Typography
                variant="small"
                color="gray"
                className="flex items-center justify-center gap-2 font-semibold text-black text-[20px] mb-4 mt-4"
            >
                {`Lista de Documentos`}
            </Typography>

            <Card className="w-full lg:w-[600px] xl:w-[600px] m-auto ">
                <List>
                    {archivos.length > 0 && archivos.map((item, index) => 
                        <ListItem 
                            key={index} ripple={false} className="py-4 pr-1 pl-4 relative"
                            onMouseEnter={() => onHoverIcon(`listItem-${index}`)}
                            onMouseLeave={onHoverLeaveIcon}
                        >
                            <ListItemPrefix>
                                <FontAwesomeIcon className="text-large color-tomato ml-4" icon={faFileAlt}/>
                            </ListItemPrefix>
                            <span className='text-[14px]'>{item.nombre}</span>
                            <DocumentArrowDownIcon
                                className={`${hoveredIcon === `listItem-${index}` ? 'flex' : 'hidden' } w-6 h-6 text-gray-500 cursor-pointer absolute right-4 
                                    top-1/2 transform -translate-y-1/2`}
                                onClick={()=> window.open(item.ruta)}
                            />
                        </ListItem>
                    )}
                </List>
            </Card>
        </>

    )
}

