import React, { useEffect, useState} from 'react'
import {Box,TextField} from "@material-ui/core";
import {
    Card,
    CardHeader,
    CardBody,
    Input,
    Button,
    Typography
} from "@material-tailwind/react";
import Axios from 'axios'
import {useParams} from 'react-router-dom'
import Alert from '@mui/material/Alert';
import {Loader} from './Loader'
import {AlertDialog} from './MUI/AlertDialog'
import {useAlumno} from '../Context/alumnoContext';

const regex_solo_numeros = /^[0-9\b]+$/;

export const CodigoSeguridad = ({usuario,retorno})=>{
    const [user,setUser] = useState(null)
    const [codigoActual,setCodigoActual] = useState(null)
    const [codigoNuevo,setCodigoNuevo] = useState(null)
    const [confirmacion,setConfirmacion] = useState(null)
    const [cargando,setCargando]= useState(false)
    const [contador,setContador]= useState(0)
    const [error,setError] = useState(null)
    const [errorPaso2,setErrorPaso2] = useState(null)
    const [mensaje,setMensaje] = useState(null)

    const [codigoActualCorrecto,setCodigoActualCorrecto] = useState(true)
    const {esCelular,usuario:usuarioaux} = useAlumno()

    const parametros = useParams()

    useEffect(()=>{
            setError(null)
            setCodigoActualCorrecto(false)
            setConfirmacion(null)
            setCodigoNuevo(null)
            setErrorPaso2(null)
    },[codigoActual])

    useEffect(()=>{
        if(contador>2){
            login()
        }
    },[contador])

    useEffect(()=>{
        if(codigoNuevo==codigoActual){
            setErrorPaso2('El nuevo código debe ser diferente al actual')
            return
        }
        if(codigoNuevo && confirmacion){
            if(codigoNuevo!=confirmacion){
                setErrorPaso2('No coinciden')
                return
            }
        }
        setErrorPaso2(null)
    },[codigoNuevo,confirmacion])

    useEffect(()=>{
        if(usuarioaux.resetpass){
            setErrorPaso2(null)
            setError(null)
            setCodigoActualCorrecto(true)
        }
    },[])

    const validarCodigoActual = async ()=>{
        try{
            const {data} = await Axios.post(`/api/usuarios/validar/codigo`,{nro_documento:usuario.dni,cod_seguridad:codigoActual})

            if (data?.usuario.dni==usuario.dni){
                setError(null)
                setCodigoActualCorrecto(true)
              }else{
                    setError('El código ingresado no coincide con sus credenciales')
                    setContador(contador+1)
                    setCodigoActualCorrecto(false)
              }

        }catch(err){
            setError(err?.response?.data?.message || 'Error en la validación')
            setContador(contador+1)
            setCodigoActualCorrecto(false)
        }
    }

    const handleKeyPress = (e)=>{
        if(e.key === 'Enter'){
            validarCodigoActual()
        }
    }  

    const cambiarCodigo = async ()=>{

        if(!(codigoNuevo && codigoNuevo===confirmacion && codigoNuevo!=codigoActual)){
            alert('Los códigos ingresados son inválidos')
            return
        }
        try{
            const {data} = await Axios.post(`/api/usuarios/actualizar/codigo`,{opcion:usuario.id_obrero,cod_seguridad:codigoNuevo})
            
            setMensaje(data.mensaje)
            
            setTimeout(() => {
                login()
            }, 1000);

        }catch(err){
            setErrorPaso2(err?.response?.data?.message || 'Error en la actualización del código de seguridad')
        }
    }

    const login = ()=>{
        sessionStorage.removeItem('REG_TOKEN')
        setTimeout(() => {
            window.location = '/login'
        }, 1000);
    }
    
    const handleChangeCodigoActual = (e)=>{
        if(regex_solo_numeros.test(e.target.value) || e.target.value==''){
                setCodigoActual(e.target.value.trim())
        }
    }

    const handleChangeCodigoNuevo = (e)=>{
        if(regex_solo_numeros.test(e.target.value) || e.target.value==''){
                setCodigoNuevo(e.target.value.trim())
        }
    }

    const handleChangeConfirmacion = (e)=>{
        if(regex_solo_numeros.test(e.target.value) || e.target.value==''){
                setConfirmacion(e.target.value.trim())
        }
    }

    if (cargando){
        return  <Loader open={true} 
        texto='Cargando...'/>
    }

    if (contador>2){
        return <div style={{minHeight:'350px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <Alert style={{fontSize:'large'}} severity="error" variant='outlined'>
                    <p>Superó la cantidad de 3 intentos permitidos</p>
                    <p>Por favor vuelva a loguearse</p>
                </Alert>
        </div>
    }


    if (!codigoActualCorrecto) {
        
        return (
            <Card className="w-full max-w-[24rem] m-auto mt-16">
                <CardHeader
                    color="gray"
                    floated={false}
                    shadow={false}
                    className="m-0 grid place-items-center px-4 py-8 text-center"
                >
                <Typography variant="h5" color="white">
                    Código de Seguridad
                </Typography>
                </CardHeader>
                <CardBody>
                    <div value="card" className="p-0">
                        <form className="mt-4 flex flex-col gap-4">
                        <div>
                            <Typography
                            variant="small"
                            color="blue-gray"
                            className="mb-2 font-medium"
                            >
                                Ingrese su código de seguridad actual
                            </Typography>
                            <Input
                                type="password" 
                                inputProps={{ maxLength: 4 }}
                                value={codigoActual}
                                placeholder="1234"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                onKeyPress={handleKeyPress} 
                                autoFocus={!esCelular}
                                onChange={handleChangeCodigoActual}
                            />
                        </div>
    
                        <Button 
                            size="lg" disabled={codigoActual && !error ? false : true}
                            className={`${codigoActual && !error ? 'bg-blue-500' : 'bg-blue-gray-200'} text-white`}
                            onClick={validarCodigoActual}
                        >
                            Continuar
                        </Button>
                        {error && (
                            <Typography
                                variant="small"
                                color="red"
                                className="mt-2 flex items-center justify-center gap-2 font-medium opacity-60"
                            >
                                {error}
                            </Typography>
                        )}
                        {codigoActual && contador > 0 && (
                            <Typography
                                variant="small"
                                color="gray"
                                className="mt-2 flex items-center justify-center gap-2 font-medium opacity-60"
                            >
                                {`Puede intentar ${contador} ${contador > 1 ? 'veces' : 'vez'} más`}
                            </Typography>
                        )}
                        </form>
                    </div>
                </CardBody>
          </Card>
        )
    }

    return <AlertDialog open={codigoActualCorrecto} 
            titulo={usuario.resetpass ? 'Primer ingreso: Reseteo de clave' : 'Cambie su código de seguridad'} 
            error={''}>
                    <Box sx={{width:!esCelular ? '380px' : '100%'}}>
                        {usuario.resetpass && <p className='mb-4'>Por favor elija un código de 4 dígitos que utilizará para acceder a la plataforma cada vez que ingrese.</p>}
                        {usuario.resetpass && <Alert style={{fontSize:'large'}} severity="info" variant='outlined'>Luego será dirigido a la pantalla de login para ingresar con su DNI y el código elegido.</Alert>}
                        {mensaje && <Alert style={{fontSize:'large'}} severity="info" variant='outlined'>{mensaje}</Alert>}
                        
                        {!mensaje && <>
                        <Box sx={{marginTop:'1rem',marginBottom:'1rem'}}>
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="mb-2 font-medium"
                            >
                                Nuevo código de seguridad
                            </Typography>
                            <Input
                                type="password" 
                                inputProps={{maxLength: 4}}
                                autoFocus={!esCelular}
                                value={codigoNuevo}
                                placeholder="Código de seguridad"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                onChange={handleChangeCodigoNuevo}
                            />
                        </Box>        
                        <Box sx={{marginTop:'1rem',marginBottom:'1rem'}}>
                            <Typography
                            variant="small"
                            color="blue-gray"
                            className="mb-2 font-medium"
                            >
                                Repita el código
                            </Typography>
                            <Input
                                type="password" 
                                inputProps={{maxLength: 4}}
                                value={confirmacion}
                                placeholder="Código de seguridad"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                onChange={handleChangeConfirmacion}
                            />
                        </Box>
                        
                        {errorPaso2 && <Alert style={{fontSize:'large'}} severity="error" variant='outlined'>{errorPaso2}</Alert>}
                        
                        <Box sx={{display:'flex',justifyContent:'space-between', marginTop:'1rem'}}>
                            {usuario.resetpass==0 && <Button style={{border:'none'}} color="primary" onClick={()=>setCodigoActual(null)}>Cancelar</Button>}
                            {!errorPaso2 && !mensaje && <Button variant="contained" className='bg-blue-500' onClick={cambiarCodigo}>Cambiar</Button>}
                        </Box>
                        
                        </>}
                    </Box>
                    
        </AlertDialog>

}