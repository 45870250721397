
import React, {useContext,useEffect,useState,useRef} from 'react'
import {Box ,Grid,IconButton,Select,makeStyles ,TableBody,MenuItem,FormControl,TableContainer,Table,TableHead,TableRow,TableCell, Backdrop} from "@material-ui/core";
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  Button,
  Typography
} from "@material-tailwind/react";
import Axios from 'axios'
import { Route, Link, Redirect } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {InicioTramite as Anexo} from '../Tramites/anexo/vistas/InicioTramite'
import {InicioTramite as Ascenso} from '../Tramites/ascenso/vistas/InicioTramite'
import {InicioTramite as Patrocinado} from '../Tramites/patrocinado/vistas/InicioTramite'
import {InicioTramite as NuevoT1} from '../Tramites/nuevot1/vistas/InicioTramite'
import {InicioTramite as Autonomia} from '../Tramites/autonomia/vistas/InicioTramite'
import {InicioTramite as Afiliacion} from '../Tramites/afiliacion/vistas/InicioTramite'
import {useAlumno} from '../Context/alumnoContext'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import HomeIcon from '@mui/icons-material/Home';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import ChatIcon from '@mui/icons-material/Chat';
import SyncIcon from '@mui/icons-material/Sync';
import {AlertDialog} from './MUI/AlertDialog'
import Estadisticas from './Estadisticas'
import {GenericCard} from '../componentes/MUI/GenericCard'
import {gql,useQuery,useMutation} from '@apollo/client'
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import {transformarFechaTramiteCompleta,transformarFechaTramiteSimple} from '../Helpers/fechas';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import TaskIcon from '@mui/icons-material/Task';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import Main from './Main'
import {abrirFormHabilitacionConvencion} from '../Helpers/utilidades-globales';



const ministrosPruebaTramites = [1165,685,6167,2890]

const objestados = {
  iniciado:{id:0,nombre:'Iniciado'},
  procRegion:{id:1,nombre:'En proceso regional'},
  procSede:{id:2,nombre:'En proceso sede'},
  aprobado:{id:3,nombre:'Aprobado'},
  rechazado:{id:4, nombre:'Rechazado'}
}


const meses = [{id:1,nombre:'Enero'},
{id:2,nombre:'Febrero'},
{id:3,nombre:'Marzo'},
{id:4,nombre:'Abril'},
{id:5,nombre:'Mayo'},
{id:6,nombre:'Junio'},
{id:7,nombre:'Julio'},
{id:8,nombre:'Agosto'},
{id:9,nombre:'Septiembre'},
{id:10,nombre:'Octubre'},
{id:11,nombre:'Noviembre'},
{id:12,nombre:'Diciembre'}]

const tramites = [{id:1,titulo:'Anexos NUEVA VERSION HOOKS',
                    descripcion:'Visualice las solicitudes de anexos en trámite y cree nuevas solicitudes',
                    detalle:'Tenga en cuenta que...',
                    pagina:'anexo'},
                    {id:2,titulo:'Anexos ORIGINAL',
                    descripcion:'Visualice las solicitudes de anexos en trámite y cree nuevas solicitudes',
                    detalle:'Tenga en cuenta que...',
                    pagina:'anexooriginal'},                    
                    {id:3,titulo:'Primera credencial',
                    descripcion:'Ingrese el archivo 1',
                    detalle:'Tenga en cuenta que...',
                    pagina:'credencial1'},
                    {id:4,titulo:'Patrocinar un nuevo ministro',
                    descripcion:'Ingrese el archivo 1',
                    detalle:'Tenga en cuenta que...',
                    pagina:'patrocinio'},
                    {id:4,titulo:'Modelo',
                    descripcion:'Trámite modelo con formik',
                    detalle:'Tenga en cuenta que...',
                    pagina:'modelo'},
                    {id:5,titulo:'Solicite la primera credencial',
                    descripcion:'Solicite la primera credencial',
                    detalle:'Tenga en cuenta que...',
                    pagina:'patrocinar'}
              ]

export const Tramites = ()=>{
    const [tiposSalida,setTiposSalida] = useState([])
    const [tramiteSeleccionado,setTramiteSeleccionado] = useState(null)
    const {usuario,snackbar} = useAlumno()
    //let history = useHistory()
    let navigate = useNavigate();
    //let { path, url } = useRouteMatch();
    const [refetchArray,setRefetchArray] =useState(null);

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    const verificarMesAnio = (item)=>{
    //Esta lógica se escribe para evitar que se seleccione un período mayor al
        // actual en base al año seleccionado y al mes.
        // Se deshabilitan los meses mayores al actual cuando
        // seleccionó el año en curso.

        const fecha = new Date()
        const anio = fecha.getFullYear()
        const mes = fecha.getMonth()

        if (anio==2022){
            if(Number(item.id-1)>mes){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
      
        // Nota: Hizo falta una lógica adicional en el context para controlar que sea correcto el perído cuando
        // cambió de año, por ejemplo si estamos en julio 2022 no puede seleccionar agosto 
        // en adelante para el año 2022 , pero si podría seleccionar agosto para el 2021
        // si luego de seleccionar agosto 2021 vuelve al año 2022 se aplica un control
        // en el context y llevo el mes al actual programaticamente.

    }

    const cerrarTramite = ()=>{
        setTramiteSeleccionado(null)
        //history.push('/tramites')
        navigate('/tramites')
    }

    const handleListItemClick = ()=>{
      console.log('link')
    }
    
      if(false)
      {
      return <div style={{minHeight:'350px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <BuildCircleIcon sx={{ fontSize: 80 }} />
          <br />
          <h2>Este servicio se encuentra en desarrollo</h2>
          <br />
          <h2>Proximamente estará disponible para realizar trámites</h2>
          <br />
        
    </div>}
      if(tramiteSeleccionado){
          return (
            <Box className='bg-none p-0 w-[90%] lg:w-[70%] ml-auto mr-auto mb-8'>
              <Box style={{width:''}}>
                  {tramiteSeleccionado?.componente({finalizar:cerrarTramite})}
              </Box>
            </Box>
          )
      }
      return (
        <Box >
          <Grid container style={{justifyContent:'center',marginTop:'20px'}}>
            <Grid item style={{width:'100%'}}>
                <Card className="m-6">
                    <CardHeader
                        color="gray"
                        floated={false}
                        shadow={false}
                        className="m-0 grid place-items-center px-4 py-8 text-center"
                    >
                    <Typography variant="h5" color="white">
                          Trámites
                    </Typography>
                    </CardHeader>
                    <CardBody>
                        <div value="" className="grid grid-cols-1 space-y-2 lg:space-y-0 xl:space-y-0 lg:grid-cols-5 xl:grid-cols-5">
                           <Button
                            className="bg-transparent shadow-none border-solid !border-[2px] !border-[#8E8E8E] text-[#111]" 
                            onClick={()=> setTramiteSeleccionado({titulo:'Solicitud de nuevo anexo', componente:(props)=><Anexo {...props}/>})}
                          >
                            Solicite un nuevo anexo
                          </Button>
                          
                          <Button 
                            className="bg-transparent shadow-none border-solid !border-[2px] !border-[#8E8E8E] text-[#111]" 
                            onClick={()=> setTramiteSeleccionado({titulo:'Solicitud de ascenso', componente:(props)=><Ascenso {...props}/>})}
                          >
                            Solicite un ascenso de rango
                          </Button>
                          {/*ministroTestTramites(usuario) && <Button variant='outlined' color='primary' onClick={()=>setTramiteSeleccionado({titulo:'Solicitud reemplazada',
                                                                      componente:(props)=><Ministro {...props}/>})}>Nuevo Ministro anterior</Button>*/}
                          {Number(usuario.iglesias) > 0 && 
                            <Button 
                              className="bg-transparent shadow-none border-solid !border-[2px] !border-[#8E8E8E] text-[#111]"  
                              onClick={()=> setTramiteSeleccionado({titulo:'Solicitud de nuevo ministro', componente:(props)=><Patrocinado {...props}/>})}
                            >
                              Solicite la primera credencial para un nuevo ministro
                            </Button>}

                          {/*Number(usuario.iglesias)>0 && <Button variant='outlined' style={{margin:'12px'}} color='primary' onClick={()=>setTramiteSeleccionado({titulo:'Solicitud de nuevo T1',
                                                                      componente:(props)=><NuevoT1 {...props}/>})}>Solicite nuevo T1</Button>*/}

                          {Number(usuario.iglesias) > 0 && ministroTestTramites(usuario) && 
                            <Button 
                              className="bg-transparent shadow-none border-solid !border-[2px] !border-[#8E8E8E] text-[#111]" 
                              onClick={()=> setTramiteSeleccionado({titulo:'Solicitud de autonomía', componente:(props)=><Autonomia {...props}/>})}
                            >
                              Solicite autonomía de anexo
                            </Button>}

                          {Number(usuario.iglesias) > 0 && ministroTestTramites(usuario) && 
                            <Button 
                             className="bg-transparent shadow-none border-solid !border-[2px] !border-[#8E8E8E] text-[#111]" 
                             onClick={()=>setTramiteSeleccionado({titulo:'Solicitud de afiliación', componente:(props)=><Afiliacion {...props}/>})}
                            >
                              Solicite afiliación
                            </Button>}  
                        </div>
                    </CardBody>
                </Card>

                {/*<>
                
                <Button onClick={()=>setTramiteSeleccionado({titulo:'Nuevo Ministro',
                                                            componente:(props)=><Patrocinar {...props}/>})}>Nuevo ministro</Button>
                </>*/}
                <ListaTramitesMinistros usuario={usuario} tramiteSeleccionado={tramiteSeleccionado} snackbar={snackbar}/>
            </Grid>
           
          </Grid>
            <AlertDialog open={tramiteSeleccionado && false} 
                    titulo={tramiteSeleccionado?.titulo} 
                    //style={{background:'rgb(96 147 179)'}}
                    //style2={{background:'rgb(186 203 213)'}}
                    style={{background:'white'}}
                    style2={{background:'white'}}
                    fullWidth={true}
                   // procesarCancelar = {()=>setTramiteSeleccionado(null)}
                   // procesarResultado={()=>setTramiteSeleccionado(null)}
                    error={''}>
                          {tramiteSeleccionado?.componente({finalizar:cerrarTramite})}
            </AlertDialog>

        </Box>
      );

      return (
        <Box>
          <ListaTramitesMinistros usuario={usuario}/>
          <Grid container>
            <Grid item xs={12} sm={4}>
                <IconButton title='Volver' onClick={()=>navigate('/home')}  aria-label="delete" size="large">
                    <HomeIcon fontSize="inherit" />     
                </IconButton>
                <Typography>
                      Trámites
                </Typography>
                <ListaTramites tramites={tramites} navigate={navigate}/>
            </Grid>
            {/*<Grid item  xs={12} sm={8} justifyContent='center' alignContent='center'>
                <Box sx={{padding:'1rem'}}>
                    <SwitchRutas usuario={usuario}/> 
                    <Redirect to={`/tramites/${tramites[0].pagina}`}/>  
                </Box> 
            </Grid>*/}
          </Grid>
        </Box>
      );

    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center' }}> 
                <FormControl>
                        {/*<FormLabel id="demo-radio-buttons-group-label">Seleccioná un instrumento</FormLabel>
                        */}
                        <Select
                            labelId="lb-mes"
                            id="sl-mes"
                            value={3}
                            label="Mes"
                            className={classes.select}
                        >
                            <MenuItem value={-1} disabled>Seleccione un mes</MenuItem>
                            {meses.map(item=>{
                                return <MenuItem disabled={verificarMesAnio(item)} value={item.id}>{item.nombre}</MenuItem>
                            })}
                        </Select>
                </FormControl>
            </Box>
}

/*
const Ascenso = ()=>{
    return <h1>ascenso</h1>
}
*/

const AltaAsenso = ()=>{

}

const ListaTramitesMinistros = ({usuario,tramiteSeleccionado,snackbar})=>{
/*  const GET_LISTADO = gql`
  query Listado ($id_obrero:Int){
      listado(id_obrero:$id_obrero){
        _id,
        descripcion,
        createdAt,
        updatedAt,
        tipo,
        region,
        distrito{
          nombre,
          id
        },
        ministro{
          nombre
        }
        status{
          texto
        },
        respuesta{
            texto,
            leido,
            fecha,
            fechaLeido,
            _id,
            usuario{
              nombre
            }
        }
        codigo_web
      }
      }
  
  `;

  
     const {loading,error,data,refetch} = useQuery(GET_LISTADO,
                                          {variables:{id_obrero:usuario.id_obrero},
                                          pollInterval: 60000 // cada 60 segundos refresca la cache para buscar actualizaciones
                                        })
*/

const GET_LISTADO = gql`
query Listado ($filtros:FiltrosListadoType){
    listado(filtros:$filtros){
      _id,
      descripcion,
      createdAt,
      updatedAt,
      tipo,
      region,
      distrito{
        nombre,
        id
      },
      ministro{
        nombre
      }
      status{
        texto
      },
      respuesta{
          texto,
          leido,
          fecha,
          fechaLeido,
          _id,
          usuario{
            nombre
          }
      }
      codigo_web
    }
    }

`;

   const {loading,error,data,refetch} = useQuery(GET_LISTADO,
                                        {variables:{filtros:{id_obrero:usuario.id_obrero}},
                                        pollInterval: 60000 // cada 60 segundos refresca la cache para buscar actualizaciones
                                      })

     const [leerMensaje,setLeerMensaje] = useState(null)
     const [idUpdateSeleccionado,setIdUpdateSeleccionado] = useState(null)

    useEffect(()=>{
        if(!tramiteSeleccionado){
          refetch() // Cada vez que tramiteSeleccionado es null hace un refetch de la query GET_LISTADO para que si terminó de crear un trámite refresque la lista
        }
    },[tramiteSeleccionado])


    const UPDATE_MENSAJE = gql`
      mutation updateMensajeLeido($id_tramite:ID!,$id_respuesta:ID!){
          updateTramiteMensajeLeido(id_tramite:$id_tramite,id_respuesta:$id_respuesta)
      }
    `
    const [updateMensajeLeido] = useMutation(UPDATE_MENSAJE);

    const updatemensaje = async (id_tramite,id_respuesta)=>{
        
      if(!id_tramite || !id_respuesta){
          snackbar.showMessage('No se encontró el identificador de la solicitud o de la respuesta','error')
          return
        }

        try{
            const {data} = await updateMensajeLeido({variables:{
                                              id_tramite,id_respuesta},refetchQueries:[GET_LISTADO]
                                            })
             //snackbar.showMessage(data.updateTramiteMensajeLeido,'info')

             setLeerMensaje(null)

        }catch(err){

          console.log(err.message) 

          if(err?.networkError?.result?.errors){
              snackbar.showMessage(err?.networkError?.result?.errors?.reduce((acum,item)=>{return `${acum} ${item.message}`},''),'error')
          }else{
              snackbar.showMessage(err?.message || JSON.stringify(err) || 'Error al ejecutar la operación.','error')
          }
          setLeerMensaje(null)
        }

        
    }

     if(loading){
        return <Main center alinear={true}>
            <p>Loading</p>
        </Main> 
     }

     if(error){
        return <Main center alinear={true}>
          <p>{error.message}</p>
        </Main> 
     }

     if (data?.listado.length==0){
          return <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <h3>No se encontraron trámites registrados a su nombre</h3>

            {/*<Button startIcon={<SyncIcon/>} variant='outlined' color='primary' onClick={()=>refetch()}>
                Refrescar
            </Button>*/}
                
          </Box>
     }

     const TABLE_HEAD = ["Tipo", "Creacion", "Actualización", "Descripción", "Región", "Distrito", "Estado", "Respuesta", "Código web"];

      return <Box sx={{display:'flex',
      flexDirection:'column',
      padding:'20px',
      alignItems:'center',
      justifyContent:'center' }}>
            {data?.listado.length>0 && <Card className="h-full w-full overflow-y-scroll p-2">
                <div className='grid grid-cols-2 mb-3 mt-3'>
                  <Typography variant="h6" color="blue-gray" className="font-normal">
                    Trámites registrados a su nombre
                  </Typography>
                  <div className='flex justify-end'>
                  <Button variant='text' color='primary' className='flex justify-end' onClick={()=>refetch()}>
                      Refrescar
                  </Button>
                  </div>
                </div>
              <table className="table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {[...data.listado].sort((a,b)=>ordenarPorFecha(b,a)).map((item, index) => (
                    <tr key={item._id} className="even:bg-blue-gray-50/50" onClickDESACTIVADO={()=>setIdUpdateSeleccionado(item)}>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {mapearTipo(item.tipo)}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          <span className='block'>{transformarFechaTramiteSimple(Number(item.createdAt))}</span>
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          <span className='block'>{transformarFechaTramiteSimple(Number(item.updatedAt))}</span>
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {item.descripcion}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {item.region}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          <span style={{textWrap:'nowrap'}}>{item.distrito.nombre}</span>
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {mostrarEstado(item.status[item.status.length-1].texto)}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {respuestas(item,setLeerMensaje)}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          <span style={{textWrap:'nowrap'}}>{item.codigo_web}</span>
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
            }
            <Backdrop
                style={{ background: 'black',color:'white', zIndex: 1000,opacity:'0.85' }}
                open={leerMensaje}
                //onClick={()=>alert(1)}
                >
                    <Mensaje mensaje={leerMensaje} cerrar={updatemensaje}/>

            </Backdrop>
            <AlertDialog open={idUpdateSeleccionado} 
                    titulo={'prueba'} 
                    //style={{background:'rgb(96 147 179)'}}
                    //style2={{background:'rgb(186 203 213)'}}
                    style={{background:'white'}}
                    style2={{background:'white'}}
                    fullWidth={true}
                    procesarCancelar = {()=>setIdUpdateSeleccionado(null)}
                   // procesarResultado={()=>setTramiteSeleccionado(null)}
                    error={''}>
                          <p>{idUpdateSeleccionado?._id}</p>
                          <p>{idUpdateSeleccionado?.tipo}</p>
                          {idUpdateSeleccionado?.tipo=='ascenso' && <Ascenso finalizar={()=>alert(1)} id={idUpdateSeleccionado?._id}/>}
                          {idUpdateSeleccionado?.tipo=='nuevo_patrocinado' && <UpdatePatrocinado id={idUpdateSeleccionado?._id}/>}
                          {idUpdateSeleccionado?.tipo=='anexo' && <UpdateAnexo id={idUpdateSeleccionado?._id}/>}
                          {idUpdateSeleccionado?.tipo=='nuevoT1' && <UpdateNuevoT1 id={idUpdateSeleccionado?._id}/>}
                          {idUpdateSeleccionado?.tipo=='afiliacion' && <UpdateAfiliacion id={idUpdateSeleccionado?._id}/>}
                          {idUpdateSeleccionado?.tipo=='autonomia' && <UpdateAutonomia id={idUpdateSeleccionado?._id}/>}
            </AlertDialog>
            </Box>
}

const UpdatePatrocinado = ({id})=>{

  const [procesando,setProcesando] = useState(false)

  const GET_PATROCINADO = gql`
  query patrocinado ($id:ID!){
    patrocinadoInfo(id:$id){
          _id,
          email,
          sexo,
          apellido,
          nombre,
          provincia{id,nombre},
          partido{id,nombre},
          activo
      }
  }
`

const {loading,error,data,refetch} = useQuery(GET_PATROCINADO,{variables:{id:id},
  onError:(err)=>alert(err.message)})

  if(loading || procesando){
    return <Box sx={{display:'flex',
                     flexDirection:'column',
                     alignItems:'center',
                     justifyContent:'center',
                     width:'100px',
                     marginLeft:'auto',
                     marginRight:'auto',
                     minHeight:'200px' }}> 
       <Typography variant='bpdy2'>
                Cargando...
     </Typography>
   </Box>
 }

 if(!data.patrocinadoInfo){
       
  return <div>
       <p>Cargando solicitud {'id'}...</p>
  </div>
  
}

return <Box>

  <p>{JSON.stringify(data.patrocinadoInfo)}</p>
</Box>
}

const UpdateNuevoT1 = ({id})=>{

  const [procesando,setProcesando] = useState(false)

  const GET_PATROCINADO = gql`
  query nuevoT1 ($id:ID!){
    nuevoT1Info(id:$id){
		_id,texto,
    acta_regional,acta_cdn,fecha_acta_regional,
    id_tramite{
      codigo_web
    }
  }
  }
`

const {loading,error,data,refetch} = useQuery(GET_PATROCINADO,{variables:{id:id},
  onError:(err)=>alert(err.message)})

  if(loading || procesando){
    return <Box sx={{display:'flex',
                     flexDirection:'column',
                     alignItems:'center',
                     justifyContent:'center',
                     width:'100px',
                     marginLeft:'auto',
                     marginRight:'auto',
                     minHeight:'200px' }}> 
       <Typography variant='bpdy2'>
                Cargando...
     </Typography>
   </Box>
 }

 if(!data.patrocinadoInfo){
       
  return <div>
       <p>Cargando solicitud {'id'}...</p>
  </div>
  
}

return <Box>

  <p>{JSON.stringify(data.patrocinadoInfo)}</p>
</Box>
}

const UpdateAutonomia = ({id})=>{

  const [procesando,setProcesando] = useState(false)

  const GET_AUTONOMIA = gql`
  query autonomia ($id:ID!){
    autonomiaInfo(id:$id){
		_id,texto,
    acta_regional,acta_cdn,fecha_acta_regional,
    id_tramite{
      codigo_web
    }
  }
  }
`

const {loading,error,data,refetch} = useQuery(GET_AUTONOMIA,{variables:{id:id},
  onError:(err)=>alert(err.message)})

  if(loading || procesando){
    return <Box sx={{display:'flex',
                     flexDirection:'column',
                     alignItems:'center',
                     justifyContent:'center',
                     width:'100px',
                     marginLeft:'auto',
                     marginRight:'auto',
                     minHeight:'200px' }}> 
       <Typography variant='bpdy2'>
                Cargando...
     </Typography>
   </Box>
 }

 if(!data.autonomiaInfo){
       
  return <div>
       <p>Cargando solicitud {'id'}...</p>
  </div>
  
}

return <Box>

  <p>{JSON.stringify(data.patrocinadoInfo)}</p>
</Box>
}

const UpdateAfiliacion = ({id})=>{

  const [procesando,setProcesando] = useState(false)

  const GET_AFILIACION = gql`
  query afiliacion ($id:ID!){
    afiliacionInfo(id:$id){
		_id,texto,
    acta_regional,acta_cdn,fecha_acta_regional,
    id_tramite{
      codigo_web
    }
  }
  }
`

const {loading,error,data,refetch} = useQuery(GET_AFILIACION,{variables:{id:id},
  onError:(err)=>alert(err.message)})

  if(loading || procesando){
    return <Box sx={{display:'flex',
                     flexDirection:'column',
                     alignItems:'center',
                     justifyContent:'center',
                     width:'100px',
                     marginLeft:'auto',
                     marginRight:'auto',
                     minHeight:'200px' }}> 
       <Typography variant='bpdy2'>
                Cargando...
     </Typography>
   </Box>
 }

 if(!data.afiliacionInfo){
       
  return <div>
       <p>Cargando solicitud {'id'}...</p>
  </div>
  
}

return <Box>

  <p>{JSON.stringify(data.afiliacionInfo)}</p>
</Box>
}

const UpdateAscenso = ({id})=>{

  const [procesando,setProcesando] = useState(false)

  const GET_ASCENSO = gql`
  query ascenso ($id:ID!){
      ascensoInfo(id:$id){
          _id,
          id_entrevista{
              usuario{nombre},
              fecha_entrevista,
              activo,
              _id
          },
          status_actual,
          ministro,
          ministro_id,
          analiticoURL,
          analitico_validado,
          acta_regional,
          acta_cdn,
          fecha_acta_regional,
          fecha_acta_cdn
      }
  }
`

const {loading,error,data,refetch} = useQuery(GET_ASCENSO,{variables:{id:id},

  

  onError:(err)=>alert(err.message)})

  if(loading || procesando){
    return <Box sx={{display:'flex',
                     flexDirection:'column',
                     alignItems:'center',
                     justifyContent:'center',
                     width:'100px',
                     marginLeft:'auto',
                     marginRight:'auto',
                     minHeight:'200px' }}> 
       <Typography variant='bpdy2'>
                Cargando...
     </Typography>
   </Box>
 }

 if(!data.ascensoInfo){
       
  return <div>
       <p>Cargando solicitud {'id'}...</p>
  </div>
  
}

return <Box>

  <p>{JSON.stringify(data.ascensoInfo)}</p>
</Box>
}

const UpdateMinistro = ({id})=>{

  const [procesando,setProcesando] = useState(false)

  const GET_MINISTRO = gql`
  query ministro ($id:ID!,$id_tramite:ID!){
    patrocinadoInfo(id:$id,id_tramite:$id_tramite){
      _id,nombre,sexo,email, apellido,activo,partido{id,nombre},provincia{id,nombre}
    }
  }
`

const {loading,error,data,refetch} = useQuery(GET_MINISTRO,{variables:{id:id,id:'1111111111111'},

  

  onError:(err)=>alert(err.message)})

  if(loading || procesando){
    return <Box sx={{display:'flex',
                     flexDirection:'column',
                     alignItems:'center',
                     justifyContent:'center',
                     width:'100px',
                     marginLeft:'auto',
                     marginRight:'auto',
                     minHeight:'200px' }}> 
       <Typography variant='bpdy2'>
                Cargando...
     </Typography>
   </Box>
 }

 if(error){
  return <Box sx={{display:'flex',
                   flexDirection:'column',
                   alignItems:'center',
                   justifyContent:'center',
                   width:'100px',
                   marginLeft:'auto',
                   marginRight:'auto',
                   minHeight:'200px' }}> 
     <Typography variant='bpdy2'>
              Se produjo un error al cargar la solicitud {JSON.stringify(error)}
   </Typography>
 </Box>
}

 if(!data.patrocinadoInfo){
       
  return <div>
       <p>Cargando solicitud {'id'}...</p>
  </div>
  
}

return <Box>

  <p>{JSON.stringify(data?.patrocinadoInfo)}</p>
</Box>
}

const UpdateAnexo = ({id})=>{

  const [procesando,setProcesando] = useState(false)

  const GET_ANEXO = gql`
  query anexo ($id:ID!){
      anexoInfo(id:$id){
          _id,
          nombre,
          contacto{
                  telefono,
                  celular
          },
          observaciones,
          iglesia_madre{
              id,
              nombre
          },
          domicilio{
                  direccion,
                  localidad,
                  barrio,
                  cod_postal,
                  provincia{
                      id,
                      nombre
                  },
                  partido{
                      id,
                      nombre
                  }
              },
          encargado{nombre,
                      esUad,
                  id_pastor_uad},
          acta_cdn,
          acta_regional,
          fecha_acta_cdn,
          fecha_acta_regional
      }
  }
`

const {loading,error,data,refetch} = useQuery(GET_ANEXO,{variables:{id:id},

  

  onError:(err)=>alert(err.message)})

  if(loading || procesando){
    return <Box sx={{display:'flex',
                     flexDirection:'column',
                     alignItems:'center',
                     justifyContent:'center',
                     width:'100px',
                     marginLeft:'auto',
                     marginRight:'auto',
                     minHeight:'200px' }}> 
       <Typography variant='bpdy2'>
                Cargando...
     </Typography>
   </Box>
 }

 if(!data.anexoInfo){
       
  return <div>
       <p>Cargando solicitud {'id'}...</p>
  </div>
  
}

return <Box>

  <p>{JSON.stringify(data.anexoInfo)}</p>
</Box>
}


const ListaTramites = ({tramites,navigate})=>{

const [selectedIndex,setSelectedIndex] = useState(0)

const handleListItemClick = (event, index,pagina) => {
  setSelectedIndex(index);
  //history.push(`/tramites/${pagina}`)
  navigate(`/tramites/${pagina}`)
};

  return <List component="nav" aria-label="main mailbox folders">
  {tramites.map((item,index)=>
          (<>
          <ListItemButton sx={{width:'100%'}}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index,item.pagina)}
              >
              <ListItemIcon>
                      <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={item.titulo} 
                        secondary={
                          <>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {item.descripcion}
                            </Typography>
                          </>
                        }
              
              />
      </ListItemButton>
      <Divider />
    </>)
  )}
</List>
}

const ministroTestTramites = (usuario)=>{
    return ministrosPruebaTramites.some(item=>item==usuario.id_obrero)
}

const respuestas = (item,setLeerMensaje)=>{
  
  const respuestas = item.respuesta;
  const id_tramite = item._id;

  if(respuestas?.length>0){
    const ultimaRespuesta = respuestas[respuestas.length-1]

    if(ultimaRespuesta.leido){
        return <>
            <span className='text-xsmall fw-700 bg-azul-tema text-white'>{transformarFechaTramiteSimple(Number(ultimaRespuesta.fecha))}</span>
            <p className='text-xsmall'>{ultimaRespuesta.texto}</p>
        </>  
    }else{
        return <><span style={{color:'red',whiteSpace:'nowrap'}}>Nueva respuesta</span> <ChatIcon onClick={()=>setLeerMensaje({ultimaRespuesta,id_tramite})} title="Ver la respuesta" sx={{cursor:'pointer'}}/></>
    }
  }else{
    return ''
  }

}

/*
 {item?.respuesta?.length > 0

    {item?.respuesta?.length > 0 ? item.respuesta[item?.respuesta?.length-1].texto : ''}
    {item?.respuesta?.length > 0 ? item.respuesta[item?.respuesta?.length-1].leido ? 'ok' : '!!' : ''}

*/


const Mensaje = ({mensaje,cerrar})=>{

    if(!mensaje){
        return null
    }

    const respuesta = mensaje.ultimaRespuesta;
    const id_tramite = mensaje.id_tramite;
    const id_respuesta = respuesta._id;

    return <Box style={{border:'solid 1px gray', borderRadius:'10px',width:'300px',padding:'10px',textAlign:'center'}}>
        <p>Mensaje enviado el: {transformarFechaTramiteSimple(Number(respuesta.fecha))}</p>
        <p>{respuesta.usuario.nombre} dice:</p>
        <Box sx={{padding:'10px'}}>
            {respuesta.texto}
        </Box>
        <Button style={{background:'tomato'}} variant="outlined" onClick={()=>cerrar(id_tramite,id_respuesta)}>
                      Entendido
        </Button>
    </Box>
}

const mostrarEstado = (status_actual)=>{

  switch(status_actual){
    case objestados.iniciado.nombre : return <div className='text-center'><HowToVoteIcon sx={{color:'#856835'}}/> <span className='text-xsmall fw-700 block'>Iniciado</span></div>
    case objestados.procRegion.nombre : return <div className='text-center'><FactCheckIcon sx={{color:'#5e7599'}}/><span className='text-xsmall fw-700 block'>En región</span></div>
    case objestados.procSede.nombre : return <div className='text-center'><TaskIcon sx={{color:'#485d7e'}}/><span className='text-xsmall fw-700 block'>En sede</span></div>
    case objestados.aprobado.nombre : return <div className='text-center'><ThumbUpOffAltIcon sx={{color:'green'}}/><span className='text-xsmall fw-700 block'>Aprobado</span></div>
    case objestados.rechazado.nombre : return <div className='text-center'><ThumbDownOffAltIcon sx={{color:'red'}}/><span className='text-xsmall fw-700 block'>Rechazado</span></div>
    default : return <span>{status_actual}</span>
  }

}

 
 
 
 const mapearTipo = (tipo)=>{
    switch(tipo){
      case 'anexo' : return 'Anexo'
      case 'nuevo_patrocinado' :
      case 'nuevo_ministro' : return 'Primera credencial'
      case 'ascenso' : return 'Ascenso'
      case 'nuevoT1' : return 'Nuevo T1'
      case 'afiliacion' : return 'Afiliación'
      case 'autonomia' : return 'Autonomía'
      default : return 'Desconocido'
    }
 }
 
 const ordenarPorFecha =(a,b)=>{
  const campoFecha = 'createdAt';

  let fa;
  let fb;
  const origen = 'mongodb' // 'sqlserver'

  if(origen=='sqlserver'){
    const dia_a = Number(a[campoFecha].substring(0,2));
    const mes_a  = Number(a[campoFecha].substring(3,5));
    const anio_a = Number(a[campoFecha].substring(6,10));

    fa = new Date(anio_a,mes_a,dia_a);

    const dia_b = Number(b[campoFecha].substring(0,2));
    const mes_b  = Number(b[campoFecha].substring(3,5));
    const anio_b = Number(b[campoFecha].substring(6,10));

    fb = new Date(anio_b,mes_b,dia_b);
  }else{
    /* cuando la fecha viene de mongodb viene en este formato... 1698066836857
    es una marca de tiempo en milisegundos desde el 1 de enero de 1970 (conocido como el "epoch" en Unix).
    */
    fa = Number(a[campoFecha]);
    fb = Number(b[campoFecha]); 
  }
 

  return fa-fb
 }