import React, {useContext,useEffect,useState} from 'react'
import {FormHelperText, Box ,Switch,FormGroup,FormControlLabel,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'
import {
    Select,
    Option, 
    Button, 
  } from "@material-tailwind/react";

export const Dependientes = ({usuario,
                            dependienteSeleccionado,
                            setDependienteSeleccionado,
                            setDependientes
                        })=>{
    const [datos,setDatos]=useState([])

    React.useEffect(()=>{
        buscarDependientes()
    },[])

    React.useEffect(()=>{
        if(datos.length>0){
            setDependienteSeleccionado(datos[0]?.id)
        }
    },[datos])

    const buscarDependientes = async ()=>{
        try{
            const {data} = await Axios.get(`api/tablasgenerales/obrero/dependientes/${usuario.id_obrero}`)
            setDatos(data.map(item=>{
                return {id:item.id,nombre:item.nombre}
            }))
            setDependientes(data)
        }catch(err){
            alert(err)
        }
    }

return (
        <Box sx={{marginTop:'20px'}} className='select-personal-data'>
            {datos.length > 0 ? (
                <Select 
                    value={dependienteSeleccionado}
                    onChange={(e) => setDependienteSeleccionado(e)}
                >
                    {
                    [{ id: '', nombre: 'Ministros dependientes'}, ...datos].map(item => (
                        <Option key={`ministros${item.id}`} value={item.id}>{item.nombre}</Option>
                        )
                    )
                    }
                </Select>
            ) : <p className='text-[#111] p-2 text-center'>Cargando datos...</p>}
        </Box>
    )
}


