
import React, {useContext, useEffect, useRef,useState} from 'react'
import {TextField,Box,
        IconButton,FormHelperText,
        MenuItem,CardHeader } from "@material-ui/core";
import {hacerScroll} from '../../Helpers/utilidades-globales'
import Alert from '@mui/material/Alert';
import {LoaderCircular} from '../LoaderCircular'
import {
    Select,
    Option
  } from "@material-tailwind/react";
import DeleteIcon from '@mui/icons-material/Delete';
    
export const MuiSelect = ({item,
                            props,
                            matrizDatos,
                            setMatrizDatos,
                            objetoDinamico,
                            esAtributoOpcional,
                            setFieldValue})=>{

    const {values,errors,handleChange} = props;
  
    const [datos,setDatos]=React.useState([])

    const yourNewRef= useRef();

    React.useEffect(()=>{

         const matrizDatosPorName = matrizDatos.filter(row=>row.key==item.name)

    // En matrizDatos guardamos el vector correspondiente a cada campo select de un formulario
    // por ejemplo colores, categorias...etc.
    // El propósito es no cargarlo 2 veces. 
    // Si ya se cargó no lo traemos del back otra vez (para evitar un request cada vez que hay un render)

      
         if(matrizDatosPorName.length==0){
          // Si el campo no se encontró en matrizDatos lo buscamos del backend si item.allowed_values es una función o si es un array cargamos directamente los valores del array 
              if(typeof(item.allowed_values)=='function'){
                item.allowed_values(props,objetoDinamico) // le paso props porque es posible que la funcion necesite consultar alguna prop del formulario
                .then(listado=>{
                        if(item?.dependeDe==undefined){ // si el campo tiene alguna dependencia se debe cargar cada vez que cambia su dependiente por eso no almacenamos en "memoria" 
                            setMatrizDatos([...matrizDatos,{key:item.name,data:listado}])
                        }
                    setDatos(listado)
                })
              }else if(Array.isArray(item.allowed_values)){
                  const listado = item.allowed_values.map(item=>Object.values(item))

                  setMatrizDatos([...matrizDatos,{key:item.name,data:listado}])
                  setDatos(listado)
              }
         }else{
              // Si el campo se encontró en matrizDatos usamos los datos que se habían traído del back la primera vez 
              setDatos(matrizDatosPorName[0].data)
         }
        
    },[item.name,props.values[item.dependeDe]]) // algunos campos del formulario están configurados con algún otro campo dependiente, si cambia su valor en el formulario debe volver a cargarse

useEffect(()=>{
    if(datos.length>0){
        // si se actualizan los datos del select seleccionar el primero solo si tiene un dependiente
        // O bien si tiene el atributo selectFirst en true
        if(item.dependeDe){
            if(!!values[item.dependeDe]){
                props.setFieldValue(item.name,datos[0][0]) 

                if(item.guardarTextoEnCampo){ // Si el campo del formulario tiene configurado un campo texto en donde guardar su descripcion
                    props.setFieldValue(item.guardarTextoEnCampo,datos[0][1])
                }
            }
        }

        if(item.selectFirst){
                props.setFieldValue(item.name,datos[0][0]) 

                if(item.guardarTextoEnCampo){ // Si el campo del formulario tiene configurado un campo texto en donde guardar su descripcion
                    props.setFieldValue(item.guardarTextoEnCampo,datos[0][1])
                }
        }

    }
},[datos])

const onChangeCustom = (event)=>{

    if(item.guardarTextoEnCampo){ // Si el campo del formulario tiene configurado un campo texto en donde guardar su descripcion
                         // no siempre es necesario pero hay casos en los que hace falta guardar en el objeto a enviar al backend la descripcion de cada campo seleccionado no solo el id
        const filaSeleccionada = datos.filter(item=>item[0]==event)
         const descripcion = filaSeleccionada[0][1] // tomo la descripcion del campo 1 de la fila cero
        props.setFieldValue(item.guardarTextoEnCampo,descripcion)
    }

    const newData = {
        target:{
            name:item.name,
            value:event
        }
    }
    handleChange(newData)
}  

if (!objetoDinamico.usuario){
        return <p>No hay usuario</p>
}

if(datos?.length==0 || !datos){
    return <React.Fragment>
            <LoaderCircular/>
            <p style={{textAlign:'center'}}>Cargando {item.label}...</p>
    </React.Fragment>
}

if(item?.hide && typeof item?.hide === 'function'){
    if(item?.hide(props)){
        return null
    }
}

return <div className='flex'>
          <div className='w-full'>
          {/*<p style={{wordWrap:'break-word'}}>{JSON.stringify(props)}</p>*/}
              <div className='select-personal-data mui-select-tailwind'>{item.label && <div>{item.label}:<span className={item.required ? 'c-obl' : ''}></span></div>}
                <Select
                    variant="standard"
                    name={item.name}
                    //label={item.label}
                    key={item.name}
                    fullWidth
                    value={values[item.name] || ''}
                    select
                    SelectProps={ item.disabled ? { IconComponent: () => null } : {}}
//                    disabled={ typeof item?.pepe === 'function' ? item?.pepe(props) || (item.disabled==true? true : item.allowedIf ? !values[item.allowedIf] : false)}
                    disabled={item.disabled==true? true : item.allowedIf ? !values[item.allowedIf] : false}
                    onChange={onChangeCustom}
                    >
                        {datos.map((e) => (
                        <Option key={e[0]} value={e[0]}>
                            {!e[1] ? e[0]:e[1]} 
                        </Option>
                      )
                    )}
                    </Select>  
                    {/*!item.required && values[item.name] && <button onClick={()=>handleDelete(item,setFieldValue,errors,values,setFieldError,setFieldTouched)}>Borrar</button>*/}                              
                <FormHelperText className="Mui-error">{ errors[item.name] && (props.touched[item.name] || props.isSubmiting) ? errors[item.name] : "" }</FormHelperText>
                </div>
          </div>
         {/*esAtributoOpcional(item) && 
              <IconButton title="Eliminar el atributo" color="secondary" size="xx-small" onClick={() => eliminar(item)}>
                    <DeleteIcon />
        </IconButton>*/}
      </div>

}