import React from 'react';
import {useState, useEffect} from 'react';
import Main from '../../componentes/Main';
import Axios from 'axios';
import Loading from '../../componentes/Loading';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Countries from './countries';
import { faCheckCircle,faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Datepicker from "react-tailwindcss-datepicker"; 
import {
  Select,
  Option, 
  Card, 
  Spinner, 
  Button, 
  Typography, 
  Input,
  Textarea,
  List,
  ListItem,
  ListItemPrefix,
  Checkbox,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem
} from "@material-tailwind/react";
import useModal from '../../hooks/useModal';
import { PencilIcon } from '@heroicons/react/24/outline';

const InitCountry = {
  name: "Argentina",
  dial_code: "+54",
  code: "AR"
}

export default function AbmObreroEditionForm({ 
  data,
  nacionalidades,
  estadosCiviles,
  tiposDocumentos,
  provincias,
  handleInputChanges,
  grabarObrero
}){
  
    // estados flags 
    const [cargandoDatosTablasGenerales,setCargandoTablasGenerales] = useState(false);
    const [cargandoDatosObrero,setCargandoDatosObrero] = useState(false);
    const [huboError,setHuboError]=useState(false)
    const [iglesiaSeleccionada,setIglesiaSeleccionada]= useState(null);
    const [country, setCountry] = useState(InitCountry);

    // Variables para manejar otras operaciones
    const [date, setDate] = useState({ 
        startDate: null, 
        endDate: null 
    });

    const {toggle, isShowing } = useModal();

    useEffect(() => { 
        if (data.dia && data.mes && data.anio) { 
            const momentDate = moment(`${data.dia.toString()}/${data.mes < 10 && data.mes.toString().length === 1 ? '0'+data.mes.toString() : data.mes.toString()}/${data.anio.toString()}`, 'DD/MM/YYYY').toDate();
            const currentDate = new Date(momentDate)
            setDate({ ...date, startDate: new Date(currentDate) })
        }
    },[])   

    useEffect(()=>{ // procesa los cierres de modales por boton cerrar y no por otro motivo
        if (!isShowing){
           
            if (iglesiaSeleccionada){
                setIglesiaSeleccionada(null)
            }        
        }
    },[isShowing])   

const handleValueChange = (newValue) => {
    const myDate = moment(newValue.startDate, 'YYYY/MM/DD');
    handleInputChanges('dia', myDate.format('DD'));
    handleInputChanges('mes', myDate.format('MM'));
    handleInputChanges('anio', myDate.format('YYYY'));
    setDate(newValue); 
}               

const getFlag = (code) => {
  const flagValue = code || country.code;
  return `https://flagcdn.com/48x36/${flagValue.toLowerCase()}.png`
}

  if (huboError){
      return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
  }

  if (cargandoDatosTablasGenerales || cargandoDatosObrero) {
      return (
          <Main center alinear>
              <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                  <Spinner className="h-12 w-12" />
                  <span className="cargando font-semibold text-[15px]">Cargando datos personales...</span>
              </div>
          </Main>
      )
  }

    return (
      <Card className={`w-[90%] lg:w-[80%] m-auto px-6 py-8 mb-2`}>
        <div className='xl:grid xl:grid-flow-row-dense xl:grid-cols-3 xl:gap-3'>
          <div className='flex flex-col mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Nombre y apellido
            </Typography>
            <Input
              value={data.nombre}
              placeholder={'Nombre y apellido'}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              onChange={(e) => handleInputChanges('nombre', e.target.value)}
            /> 
          </div>

          <div className='flex flex-col select-personal-data mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Sexo
            </Typography>
            <Select 
              value={data.sexo === 'M' ? 'Hombre' : 'Mujer'}
              onChange={(e) => handleInputChanges('sexo', e === 'Hombre' ? 'M' : 'F')}
            >
              <Option value='Hombre'>Hombre</Option>
              <Option value='Mujer'>Mujer</Option>
            </Select>
          </div>

          <div className='flex flex-col select-personal-data mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Nacionalidad
            </Typography>
            <Select 
              value={data.id_nacionalidad}
              onChange={(e) => handleInputChanges('id_nacionalidad', e)}
            >
              {
                nacionalidades.map(item => (
                    <Option key={`abmcurso-permiso${item.id_nacionalidad}`} value={item.id_nacionalidad}>{item.nombre}</Option>
                  )
                )
              }
            </Select>
          </div>

          <div className='flex flex-col select-personal-data mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Estado civil
            </Typography>
            <Select 
              value={data.id_estado_civil}
              onChange={(e) => handleInputChanges('id_estado_civil', e)}
            >
              {
                estadosCiviles.map(item => (
                    <Option key={`abmcurso-permiso${item.id_estado_civil}`} value={item.id_estado_civil}>{item.nombre}</Option>
                  )
                )
              }
            </Select>
          </div>

          <div className='flex flex-col select-personal-data mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Tipo documento
            </Typography>
            <Select 
              value={data.id_tipo_doc}
              onChange={(e) => handleInputChanges('id_tipo_doc', e)}
            >
              {
                tiposDocumentos.map(item => (
                    <Option key={`abmcurso-permiso${item.id_tipo_doc}`} value={item.id_tipo_doc}>{item.nombre}</Option>
                  )
                )
              }
            </Select>
          </div>

          <div className='flex flex-col mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Nro. documento
            </Typography>
            <Input
              value={data.nro_documento}
              placeholder={'Nro. documento'}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              onChange={(e) => handleInputChanges('nro_documento', e.target.value)}
            /> 
          </div>

          <div className='flex flex-col edition-date-picker mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Fecha de nacimiento
            </Typography>
            <Datepicker 
              i18n={"es"}
              useRange={false} 
              asSingle={true} 
              value={date} 
              onChange={handleValueChange} 
              displayFormat={"DD/MM/YYYY"} 
              readOnly={true} 
          />
          </div>

          <div className='flex flex-col mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Cónyuge
            </Typography>
            <Input
              value={data.conyuge}
              placeholder={'Cónyuge'}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              onChange={(e) => handleInputChanges('conyuge', e.target.value)}
            /> 
          </div>

          <div className='flex flex-col mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Dirección
            </Typography>
            <Input
              value={data.direccion}
              placeholder={'Dirección'}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              onChange={(e) => handleInputChanges('direccion', e.target.value)}
            /> 
          </div>

          <div className='flex flex-col mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Barrio
            </Typography>
            <Input
              value={data.barrio}
              placeholder={'Barrio'}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              onChange={(e) => handleInputChanges('barrio', e.target.value)}
            /> 
          </div>

          <div className='flex flex-col mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Localidad
            </Typography>
            <Input
              value={data.localidad}
              placeholder={'Localidad'}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              onChange={(e) => handleInputChanges('localidad', e.target.value)}
            /> 
          </div>

          <div className='flex flex-col mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Código Postal
            </Typography>
            <Input
              value={data.cod_postal}
              placeholder={'Código Postal'}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              onChange={(e) => handleInputChanges('cod_postal', e.target.value)}
            /> 
          </div>

          <div className='flex flex-col select-personal-data mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Provincia
            </Typography>
            <Select 
              value={data.id_provincia}
              onChange={(e) => handleInputChanges('id_provincia', e)}
            >
              {
                provincias.map(item => (
                    <Option key={`abmcurso-permiso${item.id_provincia}`} value={item.id_provincia}>{item.nombre}</Option>
                  )
                )
              }
            </Select>
          </div>

          <div className='flex flex-col mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Teléfono
            </Typography>
            <Input
              value={data.telefono}
              placeholder={'Teléfono'}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              onChange={(e) => handleInputChanges('telefono', e.target.value)}
            /> 
          </div>

          <div className='flex flex-col mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Celular
            </Typography>
            <div className="relative flex space-x-2 w-full -ml-2">
              <Menu placement="bottom-start">
                <MenuHandler>
                  <Button
                    ripple={false}
                    variant="text"
                    className="flex items-center rounded-md border-[1px] border-blue-gray-200 px-2 py-2 bg-[#ccc]/50"
                  >
                    <img
                      src={getFlag()}
                      width={48}
                      height={36}
                      className="h-6 w-8 object-cover mr-2"
                    />
                    <span className="w-[62px] ml-[-5px] text-blackOne">{country.dial_code}</span>
                  </Button>
                </MenuHandler>
                <MenuList className="max-h-[20rem] max-w-[18rem] overflow-x-hidden">
                  {Countries.map((value, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={value.name}
                        className="flex items-center gap-2"
                        onClick={() => setCountry(value)}
                      >
                        <img
                          src={getFlag(value.code)}
                          width={48}
                          height={36}
                          alt={value.name}
                          className="h-5 w-8 object-cover"
                        />
                        {value.name} <span className="ml-auto">{value.dial_code}</span>
                      </MenuItem>
                    );
                  }
                  )}
                </MenuList>
              </Menu>
              <Input
                value={data.celular}
                placeholder={'+54 (9) 11 1234-5678'}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                onChange={(e) => handleInputChanges('celular', e.target.value)}
              />
            </div> 
          </div>

          <div className='flex flex-col mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              E-mail
            </Typography>
            <Input
              value={data.email.toLowerCase()}
              placeholder={'E-mail'}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              onChange={(e) => handleInputChanges('email', e.target.value)}
            /> 
          </div>

          <div className='flex flex-col mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Oficio
            </Typography>
            <Input
              value={data.oficio}
              placeholder={'Oficio'}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              onChange={(e) => handleInputChanges('oficio', e.target.value)}
            /> 
          </div>

          <div className='flex flex-col mb-3'>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
            >
              Ministerio (Breve descripción)
            </Typography>
            <Textarea
              value={data.desc_ministerio}
              placeholder={'Ministerio (Breve descripción)'}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              onChange={(e) => handleInputChanges('desc_ministerio', e.target.value)}
            /> 
          </div>

          <div className='flex flex-col mb-3 col-span-3'>
              <List className="xl:flex-row">
                  <ListItem className="p-0">
                      <label
                          htmlFor="horizontal-list-react"
                          className="flex w-full cursor-pointer items-center px-3 py-2"
                      >
                          <ListItemPrefix className="mr-3">
                          <Checkbox
                              id="horizontal-list-react"
                              ripple={false}
                              defaultChecked={data.pastor}
                              className="hover:before:opacity-0"
                              onChange={(e) => handleInputChanges('pastor', e.target.checked)}
                              containerProps={{
                                  className: "p-0",
                              }}
                          />
                          </ListItemPrefix>
                          <Typography color="blue-gray" className="font-medium">
                              Pastor
                          </Typography>
                      </label>
                  </ListItem>
                  <ListItem className="p-0">
                      <label
                          htmlFor="horizontal-list-vue"
                          className="flex w-full cursor-pointer items-center px-3 py-2"
                      >
                          <ListItemPrefix className="mr-3">
                          <Checkbox
                              id="horizontal-list-vue"
                              ripple={false}
                              defaultChecked={data.maestro}
                              className="hover:before:opacity-0"
                              onChange={(e) => handleInputChanges('maestro', e.target.checked)}
                              containerProps={{
                                  className: "p-0",
                              }}
                          />
                          </ListItemPrefix>
                          <Typography color="blue-gray" className="font-medium">
                              Maestro
                          </Typography>
                      </label>
                  </ListItem>
                  <ListItem className="p-0">
                      <label
                          htmlFor="horizontal-list-svelte"
                          className="flex w-full cursor-pointer items-center px-3 py-2"
                      >
                          <ListItemPrefix className="mr-3">
                          <Checkbox
                              id="horizontal-list-svelte"
                              ripple={false}
                              defaultChecked={data.evangelista}
                              className="hover:before:opacity-0"
                              onChange={(e) => handleInputChanges('evangelista', e.target.checked)}
                              containerProps={{
                                  className: "p-0",
                              }}
                          />
                          </ListItemPrefix>
                          <Typography color="blue-gray" className="font-medium">
                              Evangelista
                          </Typography>
                      </label>
                  </ListItem>
                  <ListItem className="p-0">
                      <label
                          htmlFor="horizontal-list-svelte"
                          className="flex w-full cursor-pointer items-center px-3 py-2"
                      >
                          <ListItemPrefix className="mr-3">
                          <Checkbox
                              id="horizontal-list-svelte"
                              ripple={false}
                              defaultChecked={data.misionero}
                              className="hover:before:opacity-0"
                              onChange={(e) => handleInputChanges('misionero', e.target.checked)}
                              containerProps={{
                                  className: "p-0",
                              }}
                          />
                          </ListItemPrefix>
                          <Typography color="blue-gray" className="font-medium">
                              Misionero
                          </Typography>
                      </label>
                  </ListItem>
                  <ListItem className="p-0">
                      <label
                          htmlFor="horizontal-list-svelte"
                          className="flex w-full cursor-pointer items-center px-3 py-2"
                      >
                          <ListItemPrefix className="mr-3">
                          <Checkbox
                              id="horizontal-list-svelte"
                              ripple={false}
                              defaultChecked={data.otro}
                              className="hover:before:opacity-0"
                              onChange={(e) => handleInputChanges('otro', e.target.checked)}
                              containerProps={{
                                  className: "p-0",
                              }}
                          />
                          </ListItemPrefix>
                          <Typography color="blue-gray" className="font-medium">
                              Otro
                          </Typography>
                      </label>
                  </ListItem>
              </List>
          </div>
        </div>

        <div className='flex text-center justify-center items-center'>
            <Button 
                className="flex space-x-2 !bg-[#05AFF4]" 
                size="small" 
                onClick={() => grabarObrero(data) }
            >
                <PencilIcon color='white' width={18} className='' />
                <p>Confirmar</p>
            </Button>
        </div>
      </Card>
    )
}

function cargarCapacidades() {
    let capacidad;
    let vector_capacidad = []

    for (var i = 1; i < 100; i++) {
        vector_capacidad.push(i);
    }

    return vector_capacidad
}


function diferencia(horai,horaf,minutoi,minutof) {
    var resultado = true;
    var mensaje = '';

    var hora_desde = horai;
    var hora_hasta = horaf;
    var min_desde = minutoi;
    var min_hasta = minutof;

    var hora_desde_nummerica = Number(hora_desde + min_desde)
    var hora_hasta_nummerica = Number(hora_hasta + min_hasta)

    console.log('hora desde: ' + hora_desde_nummerica)
    console.log('hora hasta: ' + hora_hasta_nummerica)

    if (hora_desde_nummerica >= hora_hasta_nummerica) {
        resultado = false;
        mensaje = 'La hora de inicio debe ser anterior a la hora de fín'
    }

    console.log('hora_hasta_nummerica',hora_hasta_nummerica)
    console.log('hora_desde_nummerica',hora_desde_nummerica)

    return (hora_hasta_nummerica > hora_desde_nummerica  )

}

function hacerScroll(id){
    let element = document.getElementById(id);

    if(!element){return}
    element.scrollIntoView(false);
}

function filtrarRangoPermitidoParaAlta(rangos,setRangos){
    const rangosPermitidos = rangos.filter(item=>item.nombre.includes('sin credencial'))
    setRangos(rangosPermitidos)

    if (rangosPermitidos.length>0){
        return rangosPermitidos[0].id_rango
    }else{
        return null
    }
}

function filtrarRegionPermitidaParaAlta(regiones,setRegiones,id_region_usuario){
    const regionesPermitidas = regiones.filter(item=>item.id_region===id_region_usuario)
    setRegiones(regionesPermitidas)

    if (regionesPermitidas.length>0){
        return regionesPermitidas[0].id_region
    }else{
        return null
    }
}

function CondicionIglesia({iglesia}){
    return <div className="flex f-row jfc-fe text-smaller mt-2">  
        <div className="ml-4"><span>Balances:</span>{ iglesia.estado_balances==1 && <span><FontAwesomeIcon className="mr-2 ml2 color-green" icon={faCheckCircle}/>Al día</span>}
             { iglesia.estado_balances==0 && <span><FontAwesomeIcon className="mr-2 ml2 color-red" icon={faTimesCircle}/>Adeuda</span>}
        </div>
        <div className="ml-4"><span>Diezmos:</span> {iglesia.detalle_diezmos==1 && <span><FontAwesomeIcon className="mr-2 ml2 color-green" icon={faCheckCircle}/>Al día</span>}
              {iglesia.detalle_diezmos==0 && <span><FontAwesomeIcon className="mr-2 ml2 color-red" icon={faTimesCircle}/>Adeuda</span>}
        </div>
    </div>
}

function OtrasIglesiasObrero({id_obrero, iglesiasAutonomas,seleccionarIglesia}){
    const [iglesias,setIglesias]=useState([]);
    const [buscandoDatos,setBuscandoDatos]=useState(false)
    const [huboError,setHuboError] =useState(false)

    useEffect(()=>{
        let mounted=true

        const buscarOtrasIglesias = async ()=>{
            try{
                setBuscandoDatos(true)
                const vectorResultados = await Promise.all([
                    Axios.get(`/api/tablasgenerales/iglesiasobreroall/${id_obrero ? id_obrero : 0}`),
                ])

                if (mounted){ // para evitar el warning can't perform...

                    const iglesiasNoAutonomas = excluirIglesiasAutonomas(vectorResultados[0].data,iglesiasAutonomas)
                    setIglesias(iglesiasNoAutonomas)
                    setBuscandoDatos(false)
                }

            }catch(error){
                setHuboError(true)
                setBuscandoDatos(false)
            }
        }

        buscarOtrasIglesias();

        return ()=>{mounted=false} // para evitar el warning can't perform...
    },[])

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }
    
    if (buscandoDatos){
        return <Main center><Loading/><span className="cargando">Buscando iglesias...</span></Main>
    }

    return <div className="flex f-col">
    <span className="p-2 mt-2 mb-2 text-white bg-tomato inline-block-1 text-center">Otras iglesias <span>({iglesias.length})</span></span>
        {iglesias.length >0 && 
            <div>
                {iglesias.map(item=><div className="diezmos mb-2"><span className="text-smaller mr-2 tipo-iglesia whitespace-no-wrap">{item.tipo_iglesia}</span><span className="border-bottom-dotted-gray cursor-pointer" onClick={()=>seleccionarIglesia(item.id_iglesia)}>{item.iglesia}</span><br />
                <FontAwesomeIcon className="mt-2 mr-2" icon={faMapMarkerAlt}/>
                <span className="text-smaller whitespace-no-wrap">{item.provincia}</span></div>)}
            </div>
        }
        {iglesias.length == 0 && 
            <div>
                <span className="diezmos">Ninguna</span>
            </div>
        }        

    {/*<div className="flex f-col text-large">

    <span className="inline-block1 text-larger fw-100 mb-2 mt-4">Otras iglesias asociadas al ministro</span>
               
            {iglesias.map(item=><div className="ig-min"><span className="border-bottom-dotted-gray">{item.iglesia}</span>
            <span className="border-bottom-dotted-gray">{item.provincia}</span>
            </div>)}
        </div> */}                     
    </div>

  }

  function excluirIglesiasAutonomas(todas,autonomas){

    const excluirAutonomas = todas.filter(item=>!autonomas.some(iglesia=>iglesia.cod_iglesia==item.cod_iglesia))

    return excluirAutonomas
}