import React, {useContext,useEffect,useState} from 'react'
import {FormHelperText, Box ,Switch,FormGroup,FormControlLabel,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {
    Select,
    Option, 
    Chip, 
  } from "@material-tailwind/react";
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'

export const Iglesias = ({usuario,objetoModificacion,validacion,anexos})=>{
    const [datos,setDatos]=useState([])
    const [seleccionIn,setSeleccionIn] = useState(null)

    // la info se reemplaza por la prop anexos
    /*React.useEffect(()=>{
        buscarIglesias()
    },[])*/

    React.useEffect(()=>{
        if(seleccionIn){
            //const iglesia = datos.filter(i=>i.id==seleccionIn)
            const iglesia = anexos.filter(i=>i.id==seleccionIn)
            if(iglesia.length>0){
                objetoModificacion.set({...objetoModificacion.obj,
                    provincia:{id:iglesia[0].id_provincia,nombre:iglesia[0].provincia},
                    partido:{id:iglesia[0].id_partido,nombre:iglesia[0].partido},
                    id_region:iglesia[0].id_region,
                    distrito:{id:iglesia[0].id_distrito,nombre:`Distrito ${iglesia[0].id_distrito}`},
                    iglesia:{id:iglesia[0].id,nombre:iglesia[0].nombre}
                    })
            }else{
                objetoModificacion.set({...objetoModificacion.obj,
                    provincia:null,
                    partido:null,
                    id_region:null,
                    distrito:null,
                    iglesia:null
                    })
            }
         
        }
    },[seleccionIn])

    const checkvalidacion = (campo,validacion)=>{
        const objetoValidacion = validacion(true)
        const resultado = objetoValidacion[0];
        const texto = objetoValidacion[1]
        const campos = objetoValidacion[2]
    
        if(resultado==true || !(campos instanceof Array) || campos.length==0){
            return ''
        }
        if(campos.some(item=>item.campo==campo)){
            return campos.filter(item=>item.campo==campo)[0]?.texto || ''
        }else{
            return ''
        }
    }

    const buscarIglesias = async ()=>{
        try{
            const {data} = await Axios.get(`api/tablasgenerales/iglesias/${usuario.id_obrero}`)
            setDatos(data.map(item=>{
                return {id:item.id_iglesia,
                        nombre:item.nombre_igl,
                        id_region:item.id_region,
                        id_distrito:item.id_distrito,
                        id_provincia:item.id_provincia,provincia:item.provincia,
                        id_partido:item.id_partido,partido:item.partido}
            }))
            //setIglesias(data)
        }catch(err){
            alert(err)
        }
    }

return <Box className='select-personal-data mt-[20px]'>
        <h3></h3>
        
            <div className='form-card'>
                {/*<div>Anexo:<span className='c-obl'></span></div>*/}
                {anexos.length > 0 ? (
                    <Select 
                        value={seleccionIn}
                        onChange={(e) => setSeleccionIn(e)}
                    >
                        {
                        [{ id: '', nombre: 'Seleccione un anexo'}, ...anexos].map(item => (
                            <Option key={`iglesia${item.id}`} value={item.id}>{item.nombre}</Option>
                            )
                        )
                        }
                    </Select>
                ) : <p className='text-[#111] p-2 text-center'>Cargando anexos...</p>}
            </div>
        <FormHelperText className="Mui-error">{checkvalidacion('iglesia',validacion)}</FormHelperText>
        {objetoModificacion.obj?.id_region && objetoModificacion.obj?.distrito.id && (
            <Chip
                variant="ghost"
                color="green"
                size="sm"
                className='px-2 py-3 m-3 text-center text-[14px] mt-6 mb-5'
                value={`Corresponde a región: ${objetoModificacion.obj?.id_region} distrito: ${objetoModificacion.obj?.distrito?.id}`}
            />
        )}
        {/*filtro?.id_region && filtro?.id_distrito && <h3 className='mt-4 text-xlarge fw-600 color-red'>Corresponde a región: {filtro?.id_region} distrito: {filtro.id_distrito}</h3>*/}
        {/*seleccionIn && <Button style={{marginTop:'15px'}} variant="outlined" color='primary' onClick={()=>setIglesiaSeleccionada(seleccionIn)}>Confirme la iglesia madre</Button>*/}
</Box>

}


