import React, { useState } from 'react';
import {
    Card,
    Input,
    Button,
    Typography
} from "@material-tailwind/react";
import {
    IdentificationIcon,
    LockClosedIcon
} from "@heroicons/react/24/solid";
import Logo from '../componentes/Logo'
import Swal from 'sweetalert2';
import Axios from 'axios';
import {useParams} from 'react-router-dom';

export default function Login({login, mostrarError, error}){

    // la interface del usuario depende del estado, en este caso consiste solo de un objeto usuario. Cada vez que cambia el estado se vuelve a renderizar la interface
const [datosLogin,setdatosLogin] = useState({nro_documento:'',cod_seguridad:''})    

const parametros = useParams();

    React.useEffect(()=>{
        if(parametros.id_tramite && !error){
            buscarLoginTramite(parametros.id_tramite,login,mostrarError,error)
        }
    },[]);

    const handleInputChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
            const regex =/^[0-9\b]+$/
       
        if (e.target.value==''){
            setdatosLogin({...datosLogin,[e.target.name]:e.target.value})
        }else{
            if(regex.test(e.target.value)===true)
            {
                setdatosLogin({...datosLogin,[e.target.name]:e.target.value})
            }
        }
            
    }

    async function handleSubmit(e) {
        e.preventDefault();
    
        try {
          await login(datosLogin.nro_documento, datosLogin.cod_seguridad);
        } catch (err) {
            mostrarError(err);
        }
      }

      const recuperarCodSeguridad = async (documento)=>{

        if (!documento){
            return
        }

        const objetoAgrabar = { 
            documento: documento.trim()
        }
       
            try{
                  // const resultado= await Axios.post(`/api/cursos/copiar/${cuatrimestreActivo.id_cuatrimestre}`,objetoAgrabar)
                  //const resultado= await Axios.post(`/api/cursos/copiar/${cuatrimestreActivo.id_cuatrimestre}`,objetoAgrabar)
                  const resultado= await Axios.post(`/api/usuarios/recuperarid/`,objetoAgrabar)
                  return resultado
            }catch(err){
                throw err.response.data.message;
            }
        
        }

      const mensajeComunicacion = ()=>{

        const mensaje = `
        <div>
        <p>Por favor comuníquese a las siguientes direcciones de correo electrónico:</p>
        <br>
        <a target="_blank" className="mr-2 ml-2 mt-2 inline-block-1" href="mailto:info@uad.org.ar" title="info@uad.org.ar">info@uad.org.ar</a> 
        <br><br>
        <a target="_blank" className="mr-2 ml-2 mt-2 inline-block-1" href="mailto:secretaria@uad.org.ar" title="secretaria@uad.org.ar">secretaria@uad.org.ar</a> 
        <br><br>
        <p>O comuníquese a los siguientes teléfonos:</p>
        <br>
        <p>Telfax: (011) 4981 1979 / 2588 / 2957- 4958-0239</p>
        </div>
        `
        Swal.fire({
            html:mensaje,
            icon: 'info',
            width: 400,
            confirmButtonColor: '#3085d6',
        })
      }

      const buscarLoginTramite = async (id_tramite,login,mostrarError,error)=>{
            try{
                //alert(id_tramite)
                const {data} =  await Axios.get(`/api/tablasgenerales/inicio/autologin/${id_tramite}`)
                if(data.length>0){
                    await login(data[0]?.nro_documento?.toString() || '',data[0]?.cod_seguridad?.toString() || '',true)
                }else{
                    mostrarError('No se han podido identificar los datos de login.\n\n Tenga en cuenta que el link de acceso caduca una vez que ha cambiado su código de seguridad. \n\n Si el problema persiste comuníquese con la oficina UAD informando el link que utilizó para acceder a la plataforma')
                }
                //await login('5291398','11945')
                //const {data} = await Axios.get(``)
            }catch(err){
                mostrarError(err)
            }
      }

      const pedirCodSeguridad = async ()=>{

        const { value: documento ,isConfirmed,isDismissed } = await Swal.fire({
            html: '<p>Para recuperar su código de seguridad ingrese su número de documento</p>',
            width: 400,
            input: 'text',
            inputLabel: 'documento',
            inputPlaceholder: 'Número de documento',
            showCancelButton: true,
            customClass:{input:'input-rec-id'},
            inputAttributes: {
              maxlength: 10,
              autocapitalize: 'off',
              autocorrect: 'off'
            }})
    
            if (isConfirmed){
                if (documento){
                    recuperarCodSeguridad(documento)
                    .then((resultado)=>{
                        Swal.fire({
                            icon: 'alert',
                            title: 'Solicitud exitosa',
                            html: `<p>${resultado.data.message}</p>`,
                        })
                    })
                    .catch(err=>{
                        Swal.fire({
                            icon: 'error',
                            text: err
                        })
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        html: '<p>El número de documento ingresado es inválido</p>',
                      })
                }       
            }
     }

    return ( // envuelvo un contenido X con un componente Main 
    <div center className=''>  
        <div className="Signup">
            <Card className="FormContainer border-[#EBEBEB] border-[1px] login !w-[670px] shadow-lg bg-[#FAFAFA]">
                <Logo/>
                <p className="FormContainer__info mt-3 text-[#0EAAE3] text-[21px]">Login</p>
                <form onSubmit={handleSubmit}>
                    {/* Tengo conectado el input email con el estado usuario.email a través del atributo value y del evento onChange */}
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        DNI
                    </Typography>
                    <Input
                        value={datosLogin.nro_documento} 
                        onChange={handleInputChange} 
                        icon={
                            <IdentificationIcon className="absolute left-0 h-4 w-4 text-blue-gray-300" />
                        }
                        maxLength="10"
                        name="nro_documento" 
                        placeholder="DNI" 
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />

                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium mt-3"
                    >
                        Código de seguridad
                    </Typography>
                    <Input
                        type='password'
                        value={datosLogin.cod_seguridad} 
                        onChange={handleInputChange} 
                        icon={
                            <LockClosedIcon className="absolute left-0 h-4 w-4 text-blue-gray-300" />
                        }
                        maxLength="4"
                        name="cod_seguridad" 
                        placeholder="Código de seguridad" 
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                    <Button size="lg" type="submit" className='w-full mt-4 ml-0 mb-2'>Ingresar</Button>
                </form>
                {<Typography 
                    color="red" 
                    textGradient 
                    className='mt-2 text-[13px] whitespace-break-spaces text-center leading-3 font-semibold'
                >
                    {error}
                </Typography>}

                <a 
                    href="#" 
                    onClick={pedirCodSeguridad} 
                    className="no-underline hover:underline cursor-pointer text-center text-[#0EAAE3] hover:text-blue-500 mt-3 font-semibold text-sm"
                >
                    Olvidé mi código de seguridad
                </a>

                <div className="w-full h-[1px] bg-gray-800 mt-6"></div>
            
                <div className="text-center mt-6 text-small">
                    <Typography
                        variant="small"
                        className="flex items-center justify-center gap-2 font-medium opacity-90 text-[#0a0a0a]"
                    >
                        Ante la duda comuníquese con nosotros
                    </Typography>
                    
                    <div className="mt-2">
                        <a target="_blank" href="http://uad.org.ar" className='text-[#818080] font-medium'>www.uad.org.ar</a>
                    </div>
                    <div className="mt-0">
                        <a target="_blank" href="mailto:secretaria@uad.org.ar" title="secretaria@uad.org.ar" className='text-[#818080] font-medium'>secretaria@uad.org.ar</a> 
                    </div>
                    <div className="mt-0 text-[#818080] font-medium">
                        <p>+54 9 11 4981-2588</p>
                    </div>
                </div>
                {/*<button className="b-login cursor-pointer text-center fw-600 mt-2" onClick={mensajeComunicacion}>No puedo acceder a mi cuenta</button>*/}
            </Card>
        </div>
    </div>
    )
}